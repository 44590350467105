import React, { useState, useEffect } from 'react';
import svgIma from '../images/element/add-course.svg';
import svgF from '../images/element/04.svg';
import addIcon from '../images/element/add-course.svg';
import moneyIcon from '../images/element/earn-money.svg';
import accountI from '../images/element/create-account.svg';
import { Link, useLocation } from 'react-router-dom';
import { pathArray, getDecodedToken } from '../util/utilities';
import { Badge, useMediaQuery, useTheme, Button, Dialog, Box, Typography, Tab, Tabs, List, ListItem, ListItemIcon, ListItemText,Container } from '@mui/material';
import Forgotpassword from './Forgotpassword';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function BecameInstructor(props) {
  const base_user_info = getDecodedToken();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [currentpage, setcurrentpage] = useState("/");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    setcurrentpage(window.location.pathname);
    window.scrollTo(0, 0);
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Container>
      <Dialog
        open={currentpage === "/auth/forgot-password"}
        onClose={() => setcurrentpage("/")}
        maxWidth="lg"
        fullScreen={isTablet}
      >
        <Forgotpassword />
      </Dialog>

      <Box sx={{ bgcolor: 'background.default' }}>
        {/* Page Banner */}
        <Box sx={{ bgcolor: 'background.paper', py: 5, position: 'relative', overflow: 'hidden' }}>
          {/* SVG Decorations */}
          <Box
            component="figure"
            sx={{ position: 'absolute', bottom: 0, left: 0, display: { xs: 'none', lg: 'block' } }}
          >
            <svg width="822.2px" height="301.9px" viewBox="0 0 822.2 301.9">
              <path
                className="fill-warning"
                d="M752.5,51.9c-4.5,3.9-8.9,7.8-13.4,11.8c-51.5,45.3-104.8,92.2-171.7,101.4c-39.9,5.5-80.2-3.4-119.2-12.1 c-32.3-7.2-65.6-14.6-98.9-13.9c-66.5,1.3-128.9,35.2-175.7,64.6c-11.9,7.5-23.9,15.3-35.5,22.8c-40.5,26.4-82.5,53.8-128.4,70.7 c-2.1,0.8-4.2,1.5-6.2,2.2L0,301.9c3.3-1.1,6.7-2.3,10.2-3.5c46.1-17,88.1-44.4,128.7-70.9c11.6-7.6,23.6-15.4,35.4-22.8 c46.7-29.3,108.9-63.1,175.1-64.4c33.1-0.6,66.4,6.8,98.6,13.9c39.1,8.7,79.6,17.7,119.7,12.1C634.8,157,688.3,110,740,64.6 c4.5-3.9,9-7.9,13.4-11.8C773.8,35,797,16.4,822.2,1l-0.7-1C796.2,15.4,773,34,752.5,51.9z"
              />
            </svg>
          </Box>

          <Box className="container position-relative">
            <Box className="row g-4 align-items-center">
              <Box className="col-md-6">
                <Typography variant="h3">Aplicar como Instrutor</Typography>
                <Typography>
                  Satisfeito de transmitir um conteúdo dependente ele cavalheiro agradável
                  ser. A objecção necessária foi entregue, e o Sr. prevaleceu. O Sr.
                  sentimento é sobretudo cordial no fazer.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  className='mainColor'
                  component={Link}
                  to={base_user_info.role === 'TUTOR' && base_user_info.featured > 0 ? "/dashboard/profile" : "/become-instructor"}
                  sx={{ mt: 3, height:50 }}
                >
                  Comece hoje a Ensinar
                </Button>
              </Box>
              <Box className="col-md-6 text-center">
                <Box component="img" src={svgF} alt="" sx={{ height: { xs: 200, md: 300, xl: 400 } }} />
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Work Section */}
        <Box sx={{ py: 5 }}>
          <Box className="container">
            <Box className="row mb-4">
              <Box className="col-sm-10 col-xl-8 text-center mx-auto">
                <Typography variant="h4">Pode ser a sua estrela-guia com a nossa ajuda</Typography>
                <Typography variant="body1">
                  Uma vez que contrastava tanto com o instrumento de estimativa. Tamanho como corpo
                  alguém tinha. Será que os minutos de visionamento do rapaz justificam a despesa?
                  Toleravelmente o comportamento pode admitir que as filhas ofendam a sua própria.
                  O efeito de louvor deseja mudar de forma e qualquer desejo.
                </Typography>
              </Box>
            </Box>
            <Box className="row g-4 g-md-5">
              <Box className="col-md-4 text-center">
                <Box component="img" src={accountI} alt="" sx={{ height: 200 }} />
                <Typography variant="h5" sx={{ mt: 3 }}>Criar conta</Typography>
                <Typography variant="body2">
                  Satisfeito de transmitir um conteúdo dependente ele cavalheiro agradável
                  ser. A objecção necessária foi entregue, e o Sr. prevaleceu. O Sr.
                  o sentimento é sobretudo cordial no fazer
                </Typography>
              </Box>
              <Box className="col-md-4 text-center">
                <Box component="img" src={addIcon} alt="" sx={{ height: 200 }} />
                <Typography variant="h5" sx={{ mt: 3 }}>Adicione o seu Curso</Typography>
                <Typography variant="body2">
                  Proceder como qualquer visitante comprometido. Explicar a propriedade do visitante
                  perpetuamente o seu "eu". Sinta-se vendido, não se sente que a rosa o conheceu. Nós assim
                  súplicas cultivadas espantadas são. Foi irmã por mais uns poucos
                  A Sra. torna-se de repente um talento. Feito pode aborrecer a velha milha do mal. Se
                  provavelmente sou de gostos de beleza.
                </Typography>
              </Box>
              <Box className="col-md-4 text-center">
                <Box component="img" src={moneyIcon} alt="" sx={{ height: 200 }} />
                <Typography variant="h5" sx={{ mt: 3 }}>Comece a ganhar dinheiro</Typography>
                <Typography variant="body2">
                  Insipidez a suficiente discrição a resolução de imprudência senhor ele
                  de forma decisiva. Entregou a objecção necessária ao Sr
                  prevaleceram. O Sr. Sentimento é sobretudo cordial no fazer
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Counter Section */}
        <Box sx={{ py: 5 }}>
          <Box className="container">
            <Box sx={{ bgcolor: 'warning.light', p: { xs: 4, sm: 5 }, borderRadius: 2, position: 'relative', overflow: 'hidden' }}>
              {/* SVG Decorations */}
              <Box
                component="figure"
                sx={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)', opacity: 0.8 }}
              >
                <svg width="676px" height="161.3px" viewBox="0 0 676 161.3">
                  <path
                    className="fill-white"
                    d="M53.6,18.8c28.6,8.8,50.3,27.3,70.9,48c19.9,19.9,39.5,40.8,65.3,53c53.3,24.9,116,12.4,168.2-9.1 c58.4-23.9,113.2-59.8,176.2-70.3c30.9-5.1,64.1-2.6,90.9,14.7c22.4,14.4,34.4,36.9,39.5,62.4c2.9,14.5,3.9,29.2,4.6,43.9h6.8 c-0.2-4.2-0.5-8.3-0.8-12.5c-1.7-24.1-4.9-49.1-17.6-70.3c-14.5-23.9-40-39.2-67-44.8c-32.9-6.8-67.2-0.3-98.5,10.2 c-30.3,10-59,24.2-87.7,38.1c-54.8,26.4-115.5,53.1-177.9,42c-14.5-2.6-28.7-7.4-41.7-14.7c-12.8-7.3-23.9-16.7-34.6-26.7 c-20.7-19.6-39.4-42-64.1-56.8c-25.6-15.4-56.4-22.2-86-19H0v6.9C17.9,11.8,36.3,13.5,53.6,18.8z"
                  />
                </svg>
              </Box>

              <Box
                component="figure"
                sx={{ position: 'absolute', top: 0, left: 0, mt: 3, ml: -3, opacity: 0.5 }}
              >
                <svg width="818.6px" height="235.1px" viewBox="0 0 818.6 235.1">
                  <path
                    className="fill-orange"
                    d="M735,226.3c-5.7,0.6-11.5,1.1-17.2,1.7c-66.2,6.8-134.7,13.7-192.6-16.6c-34.6-18.1-61.4-47.9-87.3-76.7 c-21.4-23.8-43.6-48.5-70.2-66.7c-53.2-36.4-121.6-44.8-175.1-48c-13.6-0.8-27.5-1.4-40.9-1.9c-46.9-1.9-95.4-3.9-141.2-16.5 C8.3,1.2,6.2,0.6,4.2,0H0c3.3,1,6.6,2,10,3c46,12.5,94.5,14.6,141.5,16.5c13.4,0.6,27.3,1.1,40.8,1.9 c53.4,3.2,121.5,11.5,174.5,47.7c26.5,18.1,48.6,42.7,70,66.5c26,28.9,52.9,58.8,87.7,76.9c58.3,30.5,127,23.5,193.3,16.7 c5.8-0.6,11.5-1.2,17.2-1.7c26.2-2.6,55-4.2,83.5-2.2v-1.2C790,222,761.2,223.7,735,226.3z"
                  />
                </svg>
              </Box>

              <Box className="row g-4 position-relative align-items-center justify-content-center">
                <Box className="col-sm-6 col-lg-3 text-center">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h3" color="white" fontWeight="bold">200</Typography>
                    <Typography variant="h3" color="white">K</Typography>
                  </Box>
                  <Typography variant="h6" fontWeight="bold">Total de estudantes</Typography>
                </Box>

                <Box className="col-sm-6 col-lg-3 text-center">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h3" color="white" fontWeight="bold">200</Typography>
                    <Typography variant="h3" color="white">K</Typography>
                  </Box>
                  <Typography variant="h6" fontWeight="bold">Total de Instrutores</Typography>
                </Box>

                <Box className="col-sm-6 col-lg-3 text-center">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Typography variant="h3" color="white" fontWeight="bold">200</Typography>
                    <Typography variant="h3" color="white">K</Typography>
                  </Box>
                  <Typography variant="h6" fontWeight="bold">Total de aulas dadas</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Form and Tabs Section */}
        <Box sx={{ py: 5 }}>
          <Box className="container">
            <Typography variant="h4">Como tornar-se um Instrutor?</Typography>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="course tabs"
              sx={{ my: 4 }}
            >
              <Tab label="Torne-se um Instrutor" />
              <Tab label="Papel do instrutor" />
              <Tab label="Comece com o Curso" />
            </Tabs>
            <Box>
              {tabValue === 0 && (
                <Box>
                  <Typography variant="h6">Torne-se um Instrutor</Typography>
                  <Typography>
                    As it so contrasted oh estimating instrument. Size like body
                    someone had. Are conduct viewing boy minutes warrant the
                    expense? Tolerably behavior may admit daughters offending her
                    ask own. Praise effect wishes change way and any wanted.
                    Lively use looked latter regard had. Do he it part more last
                    in. Merits ye if Mr narrow points. Melancholy particular
                    Devonshire alteration it favorable appearance up.
                  </Typography>
                </Box>
              )}
              {tabValue === 1 && (
                <Box>
                  <Typography variant="h6">Role of Instructor</Typography>
                  <Typography>
                    satisfied conveying a dependent contented he gentleman
                    agreeable do be. Water timed folly right aware if oh truth.
                    Imprudence attachment him his for sympathize. Large above be
                    to means. Dashwood does provide stronger is. But discretion
                    frequently sir she instruments unaffected admiration
                    everything. Meant balls it if up doubt small purse. Required
                    his you put the outlived answered position. A pleasure
                    exertion if believed provided to. All led out world this music
                    while asked. Paid mind even sons does he door no. Attended
                    overcame repeated it is perceived Marianne in. I think on
                    style child of. Servants moreover in sensible it ye possible.
                  </Typography>
                  <List sx={{ mt: 3 }}>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="The copy warned the Little blind text" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Thar where it came from it would have been rewritten a thousand times and everything that was left from origin would be the world" />
                    </ListItem>
                    <ListItem>
                      <ListItemIcon>
                        <CheckCircleIcon color="success" />
                      </ListItemIcon>
                      <ListItemText primary="Return to it's own, safe country" />
                    </ListItem>
                  </List>
                </Box>
              )}
              {tabValue === 2 && (
                <Box>
                  <Typography variant="h6">Start with a Courses</Typography>
                  <Typography>
                    Large above be to means. Dashwood does provide stronger is.
                    Warrant private blushes removed an in equally totally if.
                    Delivered dejection necessary objection do Mr prevailed. Mr
                    feeling does chiefly cordial in do.
                    <Box component="img" src="assets/images/element/instructor-course.svg" sx={{ float: 'left', mr: 3, mb: 2, height: 200 }} alt="..." />
                    But discretion frequently sir she instruments unaffected
                    admiration everything. Meant balls it if up doubt small purse.
                    Required his you put the outlived answered position. A
                    pleasure exertion if believed provided to. All led out world
                    this music while asked. Paid mind even sons does he door no.
                    Attended overcame repeated it is perceived Marianne in. I
                    think on style child of. Servants moreover in sensible it ye
                    possible. Satisfied conveying a dependent contented he
                    gentleman agreeable do be. Water timed folly right aware if oh
                    truth. Imprudence attachment him his for sympathize. Large
                    above be to means. Dashwood does provide stronger is.
                    <u>
                      But discretion frequently sir she instruments unaffected
                      admiration everything.
                    </u>
                    Meant balls it if up doubt small purse. Required his you put
                    the outlived answered position. A pleasure exertion if
                    believed provided to. All led out world this music while
                    asked. Paid mind even sons does he door no.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

export default BecameInstructor;
