import React from 'react';
import { Typography } from '@mui/material';

const TruncatedText = ({text, limit }) => {
  const truncateText = (text, limit) => {
    return text.length > limit ? text.substring(0, limit) + '...' : text;
  };

  return (
    <Typography  sx={{ whiteSpace: 'nowrap'}} variant="body2">
      {truncateText(text, limit)}
    </Typography>
  );
};

export default TruncatedText;
