import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayPalComponent = (props) => {
  const {price,saveTransaction} = props;

  return (
    <PayPalScriptProvider options={{ "client-id": "ARHGaLTt7ej2FVCSawAYNyrql_IY_BiirXVNNgE8kdYvMWLfs-8PbsyEdbRS1vAn6UKpsW8ksitWd7xD" }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                amount: {
                  value: price,
                },
              },
            ],
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            saveTransaction(details);
            // const payerName = details.purchase_units[0]?.shipping?.name?.full_name;
          });
        }}
        onCancel={() => {
          alert("Payment was canceled by the user.");
        }}
        onError={(err) => {
          console.error("Payment failed due to an error:", err);
          alert("An error occurred during the transaction.");
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalComponent;
