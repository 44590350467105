import React from 'react';
import { useEffect,useRef } from 'react';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import {Link,useParams} from "react-router-dom";
import { useLocation } from 'react-router-dom';
import HashLoader from "react-spinners/HashLoader";
import {Typography,Grid, IconButton} from '@mui/material';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import {getDecodedToken,headers,sendChatToFirebase,useDisableZoomOnInputFocus,Simpleuser} from "../util/utilities";
import {useMediaQuery,useTheme} from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Network from '../network/networkRequests';


import ScheduleSendRoundedIcon from '@mui/icons-material/ScheduleSendRounded';
import CloseIcon from '@mui/icons-material/Close';
const configs =   JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function remvDuplicats(data){
  let uniqueChars = [];
  data.forEach((element) => {
      if (!uniqueChars.includes(element)) {
          uniqueChars.push(element);
      }
  });
  return uniqueChars;
}

export default function Messages(props) {
  const {instructors, onClose,isMine} = props;
    const {id} = useParams();

    
    const [isLoading,setisLoading] = React.useState(true);

    const [calendar,Oncalendar] = React.useState(false);

    const [notice,setnotice] = React.useState(true);

    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const [firedata,SetFdata] = React.useState({});
    const [inviteNotData,SetinviteNotData] = React.useState(0);
    const base_user_info = getDecodedToken();
    const [UseSendTo, UseSendToSet] = React.useState({});
    const bottomRef = React.useRef(null);
    const db = getDatabase();
    const starCountRef = ref(db, 'chat/' + base_user_info.id+'-'+id);
    const notificationCount = ref(db, 'AlertCount/' +id);
    const scrollRef = useRef(null);

    const [datecalendar,setdateCalendar] = React.useState("2024-07-27");
    const [timecalendar,settimeCalendar] = React.useState("09:40:00");
    
    React.useEffect(() => {
      setTimeout(()=>{
        setisLoading(false);
        // setnotice(false);
      }, 3000)
    }, []);
  
  
  
    const messagesget = async (payload)=>{
      
      var data = JSON.stringify({
        emailTo: payload.email
      });
  
     const response = await Network.sendRequest('post', '/message/getmessages/'+id, data);
     Setmessage(response.data);
    };


    const UseSendToFunct = async ()=>{
  
    var data = JSON.stringify({
      "id":id,
    });
    
    const response = await Network.sendRequest('post', '/message/replay', data);

      if(response.data?.error){
        // SetError(response.data.error);
      }else{
        UseSendToSet(response.data);
        messagesget(response.data);
      }
    }
  
    const [textfield, settextfield] = React.useState('')
    const [messages, Setmessage] = React.useState([]);

    function MessageBuble({item}){
      var bgImage =  ((item?.MsgFrom == base_user_info.email)?base_user_info.imageProfile:UseSendTo?.profileImage);
          return(
            <React.Fragment>
              <div className={(item?.MsgFrom == base_user_info.email)?"m-2 pb-2 d-flex flex-reverse justify-content-start":"m-2 pb-2 d-flex flex-row justify-content-end"}>
                <div className=' w-50px h-50px rounded-circle m-2 border' style={{background:"url('"+bgImage+"')", backgroundSize:"cover"}}></div>
                <div className={(item?.MsgFrom == base_user_info.email)?'bg-info rounded-5 text-white p-3 w-50':'bg-primary rounded-5 text-white p-3 w-50'}>{item?.message}</div>
              </div>
            </React.Fragment>
          );
    }
    function randomNumberInRange(min, max) {
      // 👇️ get number between min (inclusive) and max (inclusive)
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    React.useEffect(() => {
      if(messages.length > 0)
      Setmessage(messages => [...messages, firedata]);
    }, [firedata]);
  

  
  
    React.useEffect(()=>{
      window.scrollTo(0, 0);
      bottomRef.current?.scrollIntoView();
      UseSendToFunct();
  
      onValue(starCountRef, (snapshot) => {        
        const dataResponse = snapshot.val();
        SetFdata(dataResponse);
      });
  
      onValue(notificationCount, (snapshot) => {
        const dataResponse = snapshot.val();
        SetinviteNotData(dataResponse.count??0);
      });
  
    },[]);
  
  
    const handleKeyDown = (event) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault(); // Prevent the default behavior of the "Enter" key
        handleSubmit(event);
      }
    };
  
    const handleSubmit = async(e) => {
      e.preventDefault();
      var md5 = require('md5');
      if(textfield == "" && !calendar) return;
      Oncalendar(false);

      var data = {
        "MsgFrom":base_user_info?.email,
        "MsgTo": UseSendTo?.email,
        "isOpen": "0",
        "archived": "0",
        "message": calendar?"Aula marcada para "+datecalendar+" pelas "+timecalendar: textfield,
        "idMessenger":md5(UseSendTo.id),
        "ref":md5(inviteNotData+1)
      }

      
      const ky = isMine?base_user_info.id+'-'+id:id+'-'+base_user_info.id;

      set(ref(db, 'chat/'+ky),data);
      set(ref(db, 'AlertCount/'+id),{"count":inviteNotData+1});
      if(!isMine)
      Setmessage(messages => [...messages,data]);
      settextfield("");
      await Network.sendRequest('post', '/message/sendmessage', data);
      }



      React.useEffect(() => {
        const { current } = scrollRef;
        current.scrollTop = current.scrollHeight;
      });


    useDisableZoomOnInputFocus();
    
    
    return (
        <div>
        <form className=" z-index-9 " onSubmit={handleSubmit}>
          {/* Textarea */}
          <div className="">
         
           <div className='card'>
            <div className=' border-bottom d-flex justify-content-between align-items-center position-fixed fixed-top bg-white'>
              <Simpleuser item={UseSendTo}/>
              <IconButton onClick={onClose} className='p-3'><CloseRoundedIcon className='text-danger'/></IconButton>
            </div>
            <div style={{height:"60px"}}></div>
                    
            <div className='m-2 imageBobbleList' ref={scrollRef} style={{minHeight: isMobile? "80vh":"70vh"}}> 
                {messages.length < 1?<div className='w-100 d-flex justify-content-center'>{isLoading?<HashLoader color="#831caf" size={30} loading/>:<label> Envie a primeira mnsagem</label>}</div>
                :remvDuplicats(messages).map((item,index) => (<MessageBuble item={item}/>))}
                 <div ref={bottomRef} style={{height:'45px'}} />
                 { notice&&
                   <div className="p-1 mb-5">
                    <Grid container flexDirection={"column"} className='p-4 rounded-5 mainColorSeundary mainTextColor' >
                        <Typography variant="body3">{configs?.chat[0].value}</Typography>
                    </Grid>
                  </div>
                 }
            </div>
  
             <div style={{flexDirection:isMobile && calendar?"column":"row"}} className='d-flex gap-2 align-items-center p-1 bg-light position-fixed fixed-bottom justify-content-between'>
             {!calendar &&
               <textarea
               onChange={(e)=> settextfield(e.target.value)}
               style={{background:"none", border:"none"}}
               className="form-control rounded-5"
               rows={1}
               value={textfield}
               placeholder="Escreva uma memsagem"
               onKeyDown={handleKeyDown}
             />
             }
                  <div className='d-flex gap-2'>
                  {calendar &&
                    <>
                      <input onChange={(e)=>{setdateCalendar(e.target.value)}} value={datecalendar} type="date"  className="form-control"  name='calendar_date' required/>
                      <input onChange={(e)=>{settimeCalendar(e.target.value)}} value={timecalendar} type="time" className="form-control"  name='calendar_time' required/>
                    </>
                    }
                  </div>
                
              <div className='mainTextColor bg-light'>
                 <div className='d-flex align-items-center'>
                      {/* <div>
                        <IconButton type='button' onClick={()=>{Oncalendar(!calendar);}}><CloseIcon style={{color:"#f00"}}/></IconButton>
                     </div> */}
                  <button className="p-1 border border-light rounded mainTextColor" type="submit">
                  {(isMobile && calendar ) &&<span className='p-2'>Enviar marcação</span>}
                   {!calendar?
                     <SendRoundedIcon style={{fontSize:"35px"}} />:
                     <ScheduleSendRoundedIcon style={{fontSize:"35px"}}  className='mainTextColor'/>}
                    </button>
                 </div>
              </div>
             </div>
  
             </div>
          </div>
        </form>
      </div>    
    );
  }
  