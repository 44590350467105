import React, { useState,useCallback,useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  IconButton,
  Grid,
  Box,Paper,
  Divider,useMediaQuery, useTheme
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PayPalIcon from '@mui/icons-material/AccountBalanceWallet'; // Replace this with the actual PayPal icon below
import CustomPaymentIcon from '@mui/icons-material/Payment';
import CloseIcon from '@mui/icons-material/Close';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PayPalLogo from "../assets/paypal.webp";
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Network from '../network/networkRequests';
import { getDecodedToken } from '../util/utilities';
import { useAlert } from '../modules/AlertContext';
import PayPalComponent from '../modules/payments/PayPalComponent';
import PricingCardDemo from "../modules/PricingCardDemo";

const PaymentPopup = ({ open, onClose }) => {
  const [paymentMethod, setPaymentMethod] = useState('custom');
  const base_user_info = getDecodedToken();  
  const [file, setFile] = useState(null);
  const { showAlert } = useAlert();

  const [plans, setPlans] = useState([]);
  const [userPanId,setUserPanId] = useState(null);

  useEffect(() => {
    getPlan()
  }, []);

  const getPlan = async () => {
    try {
      const response = await Network.getUsersPlans({});
      setPlans(response.data);
    } catch (error) {
      console.log(error);
    }
  };


  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const saveTransaction = async(details)=>{
    
     showAlert("Aguarde...", 'success');

    try{
      var data = JSON.stringify({
        "userRef":base_user_info?.id,
        "planRef":userPanId.id,
        "paymentMethod":"PAYPAL",
        "uid":details.id,
        "status":details?.status,
        "docRef": null
      });
      
      await Network.sendRequest('post', '/payment/redirect', data).then((response)=>{
        showAlert("O seu pagamento foi/ou está a ser processado.", 'success');
        setTimeout(()=>{
          window.location.href = '/dashboard/profile';
        }, 2000);
      }).catch(e => {
        showAlert(e.toString(), 'error');
      });
    }catch(e){
      showAlert(e.toString(), 'error');
    }
  }

  const handleFinishPayment = async () => {
    // Handle the payment submission here
    onClose();
    
    var data = JSON.stringify({
      "userRef":base_user_info?.id,
      "planRef":userPanId.id,
      "paymentMethod":paymentMethod,
      "docRef": file,
      "uid":"",
      "status":""
    });
    
    await Network.sendRequest('post', '/payment/redirect', data).then((response)=>{
      showAlert("O seu pagamento foi/ou está a ser processado.", 'success');
      setTimeout(()=>{
        window.location.href = '/dashboard/profile';
      }, 2000);
    }).catch(e => {
      showAlert(e, 'error');
    });
    
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });


  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={{ borderRadius: 5 }}>
      <DialogTitle sx={{ backgroundColor: '#f5f5f5', padding: '16px 24px', borderRadius: '5px 5px 0 0', display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        }}>
        <Typography variant="h6">Finalizar Pagamento</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: '24px'}}>
      {/* {Number(base_user_info.featured) > 1 && base_user_info.role !== "NONE"? */}
      {!userPanId &&
            <Paper elevation={0} >
            <div className="text-center my-4">
                <h3 className="mainTextColor font-weight-bold">
                  O seu Pedido foi aceite!
                </h3>
                <p className=" text-secondary">
                Escolha o plano ideal para você e comece a aproveitar todos os benefícios exclusivos que oferecemos. Selecione agora o plano que melhor atende às suas necessidades e dê o próximo passo para alcançar seus objetivos!
                </p>
              </div>
              <Box className='d-flex flex-column flex-md-row gap-3'>
                {plans?.map((item, index) => (
                  <Box key={index} className='flex-fill'>
                    <PricingCardDemo planDetail={item} setpay={setUserPanId} selected={2} />
                  </Box>
                ))}
              </Box>
            </Paper>}

      {userPanId && <div>
        <div className='border p-2 mt-2 rounded mb-2'>
          <Typography variant="body1">Selecione o seu método de pagamento</Typography>
          <div className='d-flex justify-content-between  p-2'>
          <div>
                <span> Plano mensal(<strong>{userPanId?.name}</strong>)
                  <IconButton aria-label="edit" onClick={()=>{setUserPanId(null)}}>
                    <EditIcon />
                  </IconButton>
                </span>
                <p className='mainTextColor' style={{fontSize:"12px"}}>Duracão: 30 dias</p>
              </div>
              <div className='text-end d-flex flex-column gap-3'>
                <h5>{userPanId?.price} AKZS</h5>
                <sup className='d-flex'> <p className='mainTextColor' style={{fontSize:"12px", marginRight:"5px"}}>Adicionar desconto</p> <p> - +10% de IVA</p></sup>
              </div>
         </div>
    
      </div>
        <RadioGroup value={paymentMethod} onChange={handlePaymentChange}>
          <Grid container spacing={2} justifyContent={"space-between"}>
            <Grid item xs={12} sm={5} >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px',
                  height:"48px",
                  borderRadius: '5px',
                  marginBottom:"11px",
                  border: paymentMethod === 'creditCard' ? '1px solid #1976d2' : '1px solid #ccc',
                  backgroundColor: paymentMethod === 'creditCard' ? '#e3f2fd' : '#fafafa',
                  cursor: 'pointer',
                }}
                onClick={() => setPaymentMethod('creditCard')}
              >
                <CreditCardIcon style={{ marginRight: 8 }} />
                <Typography>Transferência(BAI)</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height:"48px",
                  borderRadius: '5px',
                  marginBottom:"14px",
                  border: paymentMethod === 'custom' ? '1px solid #1976d2' : '1px solid #ccc',
                  backgroundColor: paymentMethod === 'custom' ? '#e3f2fd' : '#fafafa',
                  cursor: 'pointer',
                }}
                onClick={() => setPaymentMethod('custom')}
              >
                <CustomPaymentIcon style={{ marginLeft: 8, marginRight: 8 }} />
                <Typography>Mtx Express</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}  style={{textAlign:"center"}}>
              <PayPalComponent price={userPanId?.price} saveTransaction={saveTransaction}/>
            </Grid>
          
          </Grid>
        </RadioGroup>

        <Divider sx={{ margin: '24px 0' }} />

        {paymentMethod === 'creditCard' && (
          <Box>
            <div className='d-flex'>
            <TextField fullWidth label="IBAN: 0055.0000.0000.0000.00" margin="normal" variant="outlined" disabled />
            <IconButton>
              <div className='p-2'>
                  <ContentCopyIcon/>
              </div>
            </IconButton>
            </div>
            <Grid container spacing={2}>
              <Box
              className='m-3 mt-5'
              {...getRootProps()}
              sx={{
                border: '2px dashed #ccc',
                borderRadius: '8px',
                padding: '24px',
                textAlign: 'center',
                backgroundColor: '#f9f9f9',
                cursor: 'pointer',
                marginTop: '16px',
                position: 'relative',
                width:'100%'
              }}
            >
              <input {...getInputProps()} />
              <Typography variant="body1" sx={{ color: '#888', marginTop: '8px' }}>
                Arraste e solte a prova de pagamento aqui, ou clique para selecionar um arquivo
              </Typography>
              <i
                className="bi bi-file-earmark-image"
                style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  fontSize: '80px',
                  color: '#ddd',
                  transform: 'translate(-50%, -50%)',
                  pointerEvents: 'none',
                  opacity:0.2,
                  zIndex: 0,
                }}
              ></i>
              </Box>
              <Grid item xs={12} className='m-2 mt-0'>
              <Typography>Seu pedido será cuidadosamente processado dentro de um prazo de até 24 horas, caso o pagamento seja efetuado por meio de um banco diferente. Agradecemos sua compreensão e paciência durante esse período.</Typography>
              </Grid>
            </Grid>
          </Box>
        )}

        </div>}

      </DialogContent>
      <DialogActions sx={{ padding: '16px 24px', backgroundColor: '#f5f5f5', borderRadius: '0 0 5px 5px' }}>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={handleFinishPayment}
          variant="contained"
          disableElevation
          className='mainColor'
          style={{
            height: '50px',
            fontWeight: 'bold',
            transition: 'background 0.3s ease',
            '&:hover': {
              // backgroundColor: theme.palette.primary.dark,
              color: '#fff',
            },
          }}
         // style={{borderRadius: '8px', padding:"10px"}}
          sx={{ marginLeft: '8px', backgroundColor: '#8a2be2', '&:hover': { backgroundColor: '#7a1cb5' } }}
        >
          Concluir
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentPopup;
