import axios from 'axios';
const MAIN_URL = "https://api.eureca.ao/v0.1";

const MAIN_HEADER = {
  'Content-Type': 'application/json',
  'Authorization': 'Bearer ' + localStorage.getItem('token'),
};

const Network = {
  sendRequest: async (method, url, data, headers) => {
    let config = {
      method: method,
      maxBodyLength: Infinity,
      url: MAIN_URL + url,
      headers: headers || MAIN_HEADER,
    };

    if (method.toLowerCase() === 'post') {
      config.data = data;
    } else if (method.toLowerCase() === 'get') {
      config.params = data;
    }
    
    const response = await axios.request(config);

    if(response.data.token || response.data.refreshToken){
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("refreshToken", response.data.refreshToken);
    }

    return response;
  },
  getUsersData:async (params) => {
    return Network.sendRequest('get', '/users', params);
  },
  getUsersPlans:async (params) => {
    return await Network.sendRequest('get', '/plans', params);
  },

  getUsersInvites:async (params) => {
    return await Network.sendRequest('get', '/message/getenvites', params);
  },
  
  getallSubjects:async (params) => {
    return await Network.sendRequest('post', '/subjects/profile/getsubjects', params);
  },
  getRequestApply:async (userid) => {
    return await Network.sendRequest('post', '/admin/applyviewer', {userid: userid});
  },
  submitRequestequestApply:async (data) => {
    return await Network.sendRequest('post', '/request/sendinvite', data);
  },
  setPriceUpdate:async (body) => {
    return await Network.sendRequest('post', '/updateuserdata', body);
  },

};

export default Network;