import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import html2canvas from 'html2canvas';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

const Camera = (props) => {
  const {setImageSrc} = props;

  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = React.useState(null);

  const dataURLtoBlob = dataURL => {
    const parts = dataURL.split(';base64,');
    const contentType = parts[0].split(':')[1];
    const byteString = atob(parts[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      uint8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: contentType });
  };

  const capture = () => {
    const canvasRef = document.createElement('canvas');
    const video = webcamRef.current.video;

    html2canvas(video).then(canvas => {
      canvasRef.width = canvas.width;
      canvasRef.height = canvas.height;
      canvasRef.getContext('2d').drawImage(canvas, 0, 0);

      const dataUrl = canvasRef.toDataURL('image/png');

      const blob = dataURLtoBlob(dataUrl);
      setImgSrc(dataUrl);
      setImageSrc(dataUrl);

      localStorage.setItem('image', dataUrl);
    });
  };

  const handlePermission = async () => {
    
    setImgSrc(null);

    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
    } catch (error) {
      console.error('Failed to get camera access', error);
    }
  };

  return (
    <div>
     <div className='rounded-2'>
        {imgSrc? <img src={imgSrc} />:<Webcam audio={false} ref={webcamRef} />}
     </div>

      {!imgSrc && <div><button onClick={capture} className='rounded-2 btn text-white bg-danger m-2'><PhotoCameraIcon/> <span>Shoot</span> </button></div>}

      {imgSrc && <div className='d-flex w-100  justify-content-between'> 
      <button onClick={handlePermission} className='btn mainTextColor m-1'>Voltar a Tentar?</button>
      <button onClick={()=>{
         props.handleClosePopup();
      }} className='btn mainColor text-white m-1'>Salvar</button></div>}
    </div>
  );
};

export default Camera;
