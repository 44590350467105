import React, { useState } from 'react';
import pec1 from '../images/Pic1.png';
import pec2 from '../images/Pic2.png';
import pec3 from '../images/Pic3.png';
import pec4 from '../images/Pic4.png';
import pec5 from '../images/Pic5.png';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const tips = [
  {
    title: 'O cérebro humano só aprende se estiver em “alerta e focado”.',
    content: (
      <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', margin: '20px 0' }}>
        <img src={pec1} alt="Pessoa com dúvida" style={{ float: 'left', maxWidth: '30%', marginRight: '20px', marginBottom: '20px', borderRadius: '8px' }} />
        <Typography variant="h5" component="h2" style={{ fontWeight: 'bold', marginBottom: '10px', color:"#fff" }}>
          O Caminho do Aprendizado: A Importância de Manter-se Ativo
        </Typography>
        <Typography variant="body1" component="p" style={{ marginBottom: '15px', color:"#fff" }}>
          É um fato incontestável: após a infância, para aprender, é necessário manter-se “ativo” e recorrente. Caso contrário, o sucesso se torna difícil de alcançar. O processo de aprendizado vai muito além de métodos convencionais. É um jogo de tentativas e erros até encontrar a abordagem que se adapta melhor ao que buscamos.
        </Typography>
        <Typography variant="body1" component="p" style={{ marginBottom: '15px', color:"#fff" }}>
          Não há uma técnica de estudo superior a outra. Cada um de nós possui um estilo único de aprender, e isso deve ser respeitado. Uma técnica que pode ser extremamente eficaz para um determinado tema pode ser completamente ineficaz para outro. A chave está em conhecer-se profundamente.
        </Typography>
        <Typography variant="body1" component="p" style={{ marginBottom: '15px', color:"#fff" }}>
          O truque é simples: conheça-se bem e ajuste as técnicas de estudo de acordo com o que melhor se adapta ao seu estilo.
        </Typography>
      </div>
    ),
    backgroundColor: '#AB47BC',
  },
  {
    title: 'Aprendes melhor…',
    content: (
      <ul className="list-group list-group-borderless  ">
        {[
          '…sozinho ou em grupo?',
          '…com música ou TV ligada (luzes da tela)?',
          '…em casa ou num espaço público?',
          '…no silêncio ou no ruído?',
          '…em auto aprendizado ou com auxílio de outrem?',
        ].map((text, index) => (
          <li key={index} className="list-group-item h6 fw-normal d-flex text-white">
            <i className="bi bi-patch-check-fill text-success me-2" />
            {text}
          </li>
        ))}
      </ul>
    ),
    backgroundColor: '#AB47BC',
  },
  {
    title: 'Método Cornell',
    content: (
      <div className='d-flex gap-3 flex-column flex-md-row '>
        <img src={pec2} alt="Método Cornell" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <div>
          <Typography variant="body1" component="p" className='text-white'>
            Embora seja antigo, continua válido e produtivo. É uma boa forma para resumirmos e aprendermos os temas de forma organizada…
          </Typography>
          <Typography variant="body1" component="p"  className='text-white'>
            É bem simples de ser aplicado e para tal só precisas de uma folha, a esferográfica e “brilhar”.
          </Typography>
        </div>
      </div>
    ),
    backgroundColor: '#CE93D8',
  },
  {
    title: 'Sistema de Leitner',
    content: (
      <div className='d-flex gap-3 flex-column flex-md-row text-white'>
        <img src={pec3} alt="Sistema de Leitner" style={{ maxWidth: '100%', marginBottom: '15px' }} />
        <div>
            <Typography variant="body1" component="p">
              Essa técnica tem a grande vantagem de manter o teu cérebro em “recordação ativa” no conteúdo em estudo, devido a sua recorrência e dinâmica.
            </Typography>
            <Typography variant="body1" component="p">
              Basicamente, é um método de aprendizagem que usa cartões rápidos (flashcards), caixinhas para os cartões e um agendamento para as repetições diárias, semanais e ou mensais.
            </Typography>
        </div>
      </div>
    ),
    backgroundColor: '#BA68C8',
  },
  {
    title: 'Plano de estudo',
    content: (
      <>
        <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', backgroundColor: '#AB47BC', padding: '20px', borderRadius: '8px' }}>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <img src={pec4} alt="Plano de estudo" style={{ float: 'left', maxWidth: '35%', marginRight: '20px', marginBottom: '20px', borderRadius: '8px' }} />
            <div style={{ flex: '1' }}>
              <Typography variant="h5" component="h2" style={{ color: '#fff', fontWeight: 'bold', marginBottom: '10px' }}>
                A Importância de Criar um Plano de Estudo Eficiente
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#fff', marginBottom: '15px' }}>
                Para alcançar o sucesso acadêmico, é essencial ter uma rotina de estudo bem definida. Um plano diário de estudos, que distribua as disciplinas de acordo com a dificuldade de cada uma, é uma das melhores formas de organizar o aprendizado. Com o uso de um calendário de estudo, você pode visualizar claramente suas prioridades e garantir que está fazendo o melhor uso do seu tempo.
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#fff', marginBottom: '15px' }}>
                Ao dividir o seu tempo de forma inteligente, você cria uma rotina sustentável, reduz a procrastinação e maximiza os seus resultados. Além disso, lembre-se de que a flexibilidade no planejamento é importante, pois imprevistos podem ocorrer, e a capacidade de adaptação é fundamental para o sucesso.
              </Typography>
            </div>
          </div>
          <img src={pec5} alt="Calendário de estudo" style={{ maxWidth: '100%', marginTop: '20px', borderRadius: '8px' }} />
        </div>
      </>
    ),
    backgroundColor: '#AB47BC',
  }  
];

function GeneralTips() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="container" display="flex" flexDirection="column" alignItems="center">
   
      <Card
        style={{
          width: '100%',
          backgroundColor: tips[activeStep].backgroundColor,
          margin: '20px',
          padding: '20px',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom className='text-white'>
            {tips[activeStep].title}
          </Typography>
          {tips[activeStep].content}
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" width="100%" mt={2}>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBack}
          className='rounded-2'
          disabled={activeStep === 0}
          style={{visibility:activeStep !== 0?"visible":"hidden"}}
          startIcon={<ArrowBack />}
        >
          Anterior
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className='rounded-2'
          onClick={handleNext}
          disableElevation
          style={{visibility:activeStep === tips.length - 1?"hidden":"visible "}}
          disabled={activeStep === tips.length - 1}
          endIcon={<ArrowForward />}
        >
          Próxima Dica
        </Button>
      </Box>
    </Box>
  );
}

export default GeneralTips;
