import React, { useState, useEffect } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import HashLoader from "react-spinners/HashLoader";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import { IconButton,Badge,Dialog,DialogTitle,DialogContent,DialogActions,useTheme,useMediaQuery  } from '@mui/material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import VideocamRoundedIcon from '@mui/icons-material/VideocamRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from '@mui/x-date-pickers/StaticDateTimePicker';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { ptBR } from '@mui/x-date-pickers/locales';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useAlert } from '../modules/AlertContext';
import Network from '../network/networkRequests';

export default function Recadule(props) {
    const {open, handleClose, isMobile, item, isMine, sendApprove, loading} = props;
    const { showAlert } = useAlert();

    // const getNextDayWithTime = () => {
    //     const now = new Date();
    //     now.setDate(now.getDate() + 1); // Avançar um dia
    //     now.setHours(9, 0, 0, 0); // Definir a hora para 9:00 AM
    //     return now;
    // };

    const [value, setValue] = React.useState("");

    const handleAccept = async (newValue)  => {
        if (isWorkHour(new Date(newValue))) {
            const dateAll = formatDate(newValue);
            setValue(formatDate(newValue));
            showAlert("Aguarde..", 'info');

            const data = {"hour":dateAll.split(" ")[0],"date":dateAll.split(" ")[1],}
            await Network.sendRequest('post','/scheduling/set/'+item.id,data).then(response => {
                showAlert("A sua aula foi marcada!", 'success');
                handleClose();
              }).catch(error=>{
                showAlert("Erro na marcacão de sua aula, tente mais tarde", 'error');
              });
            
        } else {
            showAlert("Por favor, escolha um horário dentro do horário comercial (9h às 17h).", 'error');
        }
    };

    function formatDate(inputDate) {
        const date = new Date(inputDate); // Garante que é um objeto Date
        const pad = (n) => (n < 10 ? `0${n}` : n);
        const hours = pad(date.getHours());
        const minutes = pad(date.getMinutes());
        const seconds = pad(date.getSeconds());
        const year = date.getFullYear();
        const month = pad(date.getMonth() + 1);
        const day = pad(date.getDate());
        return `${hours}:${minutes}:${seconds} ${year}-${month}-${day}`;
    }
    
    const isWorkHour = (date) => {
        const hours = date.getHours();
        return hours >= 9 && hours <= 17;
    };

    return <Dialog
        open={open}
        onClose={handleClose}
        fullScreen={isMobile}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <div style={{ minWidth:isMobile? "20em":"30em" }}>
            {isMobile &&
                <DialogActions>
                        <IconButton onClick={handleClose} autoFocus>
                            <CloseRoundedIcon style={{ color: "#f00" }} />
                        </IconButton>
                </DialogActions>}
            <DialogTitle id="alert-dialog-title">Assistente de marcação</DialogTitle>

            <DialogContent className='p-3'>

            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="pt" // Configura o idioma no AdapterDayjs
            localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                {isMobile?<MobileDateTimePicker
                    value={value}
                    className='w-100'
                    onChange={(newValue) => setValue(newValue)}
                    onAccept={handleAccept}
                    ampmInClock={false}
                    ampm={false}
                    label="Selecione uma data e hora"
                    inputFormat="dd/MM/yyyy HH:mm"
                />:
                <StaticDateTimePicker orientation="landscape"
                    value={value}
                    ampmInClock={false}
                    ampm={false}
                    onAccept={handleAccept}
                    style={{ borderColor: 'red', color: 'red' }}
                    inputFormat="dd/MM/yyyy HH:mm"
                    onChange={(newValue) => setValue(newValue)}
                />}
            </LocalizationProvider>
                {/* <Typography>
                    <div className="d-flex gap-2 flex-column mb-3">
                        <label className="form-control"><b>Data:</b> {item?.date}</label>
                        <label className="form-control"><b>Hora:</b> {item?.hour}</label>
                        <label className="form-control"><b>Disciplina:</b> {item?.subject}</label>
                    </div>

                    <div className='d-flex gap-2 justify-content-between'>
                        {isMobile &&
                            <Button onClick={handleClose} style={{ color: "#f00" }}>Cancelar Pedido</Button>}
                        <Link to={'/dashboard/inbox/' + item?.user?.id}>
                            <Button variant="contained" disableElevation className="btn btn-sm bg-info bg-opacity-10 text-primary"><strong>Reagendar?</strong>  <div className='p-1'></div> </Button>
                        </Link>

                    </div>
                </Typography> */}
            </DialogContent>

            {isMobile && <DialogActions>
                {item?.accepted ?
                    <Button startIcon={<CalendarMonthIcon/>} variant="contained" fullWidth={isMobile} className="d-flex gap-2 btn mainColor btn-round mb-0 text-center  p-2" style={{ minWidth: "10em" }}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"> <strong>Marcar Aula</strong>
                    </Button> : isMine ? <Button fullWidth={isMobile} variant="contained" className="btn btn-sm mainBtn mainColor bg-opacity-10 text-white d-flex justify-content-center p-2" disableElevation onClick={sendApprove}>{loading ? <HashLoader color="#ffffff" size={20} loading /> : <>Aceitar</>}</Button> : <></>}
            </DialogActions>}
        </div>
    </Dialog>;
}
