import React,{useEffect, useState} from 'react';
import {useMediaQuery,Button,useTheme,Dialog,Grid,Box,Paper,Avatar,Rating,Chip
,FormControl,InputLabel,Select,MenuItem,IconButton
} from '@mui/material';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import VerifiedIcon from '@mui/icons-material/Verified';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import {getDecodedToken} from '../util/utilities';
import { getDatabase, ref, set,get,onValue,child} from "firebase/database";
import HashLoader from "react-spinners/HashLoader";
import Network from '../network/networkRequests';

var CurrencyFormat = require('react-currency-format');

export default function ContactDialog(props) {
  const {setopenContactar,open, single, subjets,userInfo} = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [notificationCount,setnotificationCount] = useState(0);
  const firebaseSavePath = 'AlertCount/' + single?.id;

  React.useEffect(()=>{
    onValue(ref(db, firebaseSavePath), (snapshot) => {
      const dataResponse = snapshot.val();
      setnotificationCount(dataResponse.count??0);
    });
  },[]);



  const [subjetsTpm, setsubjetsTpm] = React.useState(0);
  const base_user_info = getDecodedToken();
  const [error,SetError] = React.useState({});
  const [payload,SetPayload] = React.useState({});

  const [lesonTypes, setlesonTypes] = React.useState(["Online","Presencial"]);
  const [selectedLesonTypes, setselectedLesonTypes] = React.useState(0);



  const [paymentMethod, setpaymentMethod] = React.useState(["Mtcx Express","Paypal","Transferencia"]);
  const [selectedpaymentMethod, setselectedpaymentMethod] = React.useState(0);
  const [loading,Setloading] = React.useState(false);
  const db = getDatabase();

  
  const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

  const handleChange = (event) => {
    setsubjetsTpm(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var data = JSON.stringify({
      "MsgFrom":base_user_info?.id,
      "MsgTo": single?.id,
      "isOpen": "0",
      "archived": "0",
      "message": "Pedido de aula",
      "details": {
        "date":"000",
        "hour": "000",
        "subject": subjets[subjetsTpm],
        "type":lesonTypes[selectedLesonTypes],
        "paymentType":paymentMethod[selectedpaymentMethod],
      },
    });

    Setloading(true);

    set(ref(db, firebaseSavePath),{"count":notificationCount+1});

    await Network.submitRequestequestApply(data).then((response)=>{
      if(response.data?.error){
        console.log(error);
        SetError(response.data.error);
      }else{
        SetPayload(response.data);
        window.location.href = '/dashboard/invits';
      }
      console.log(response.data);
    }).catch(error => {

      console.log(error);
      SetError(error);
      Setloading(false);
    });
  }
  
  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={setopenContactar}
      >
   {!isMobile &&
       <IconButton size="small" style={{position:"absolute", left:"10px", top:"10px"}} onClick={setopenContactar}>
       <ArrowBackRoundedIcon style={{fontSize:"1.8em"}}/>
       </IconButton>
   }
      <Grid container justifyContent={"center"} style={{width:isMobile?"100%":"70vw", margin:"auto", marginTop:isMobile?"0px":"10px"}}>
     
        {/* Coluna de perfil do usuário */}
        <Grid item xs={12} md={3} className={isMobile? 'p-1 rounded-5 m-3':''}>
          <Box sx={{ position: 'sticky', top: 80 }} className={"text-center"}>
            <Paper  elevation={!isMobile?1:0} sx={{ padding: 2}} style={{background:isMobile?"transparent":"#fff"}} className='rounded-5 w-100 shadow'>
              <Box display="flex" flexDirection={isMobile?"row":"column"} alignItems="center">
                <div className='d-flex gap-1'>
                {isMobile && <IconButton size="small" onClick={setopenContactar}>
                  <ArrowBackIosNewRoundedIcon style={{fontSize:"1.2em"}}/>
                </IconButton>}
                <Avatar src={single?.profileImage?single?.profileImage:"https://ui-avatars.com/api/?name="+single?.nome} sx={{ minWidth: 140 / (isMobile?2:1), height: 140 / (isMobile?2:1), mb: 1 }} className={'rounded-4'} />
                </div>
               {
                isMobile ?
                 <Grid container style={{padding:"10px"}}>
                  <Grid item style={{fontSize:"12px", fontWeight:"bold"}} className='mainTextColor'><StarRoundedIcon className='mainTextColor'/> <strong>{single?.nome} oferece a sua 1ª aula para que possam conhecer-se melhor.</strong></Grid>
                </Grid>:
                <div>
                  <Typography style={{color:isMobile?"#fff":"#000", fontWeight:"bold"}}>{single?.nome}</Typography>
                  {/* <Rating value={2} readOnly /> */}
                  <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }} style={{color:isMobile?"#fff":"#000"}}>{userInfo?.user?.ratingCount??0} avaliações</Typography>
               </div>}
             
              </Box>
            </Paper>
            {
              !isMobile &&
            <Grid container justifyContent={"center"} direction={"row"} alignItems={"center"} style={{padding:"10px"}}>
                <Grid item><StarRoundedIcon className='mainTextColor'/></Grid>
                <Grid item style={{fontSize:"12px", fontWeight:"bold"}}>{single?.nome} oferece a sua 1ª aula para que possam conhecer-se melhor.</Grid>
              </Grid>}
            {/* <div>
              <Typography className='text-center p-2'>Clau oferece a sua 1ª aula para que possam conhecer-se melhor.</Typography>
            </div> */}
            
          </Box>
        </Grid>

        {/* Conteúdo principal */}
        <Grid item xs={12} md={7} className={isMobile?'p-4':'p-5'}>
        <Grid item style={{height:"20px"}}></Grid>

          <Grid item style={{height:isMobile?"0px":"0em"}}></Grid>

          <Grid container justifyContent={"space-between"}>
             <Grid item>
                <Typography variant="body3"  className='h4'>Reserve a</Typography>
             </Grid>
             <Grid item>
                <div ><Chip className='mainColorSeundary' label={<span className='d-flex gap-1 align-items-center '><strong><CurrencyFormat style={{fontSize:"20px"}} className='mainTextColor' value={single?.price??0} displayType={'text'} format="#.### AOA"/></strong></span>}/></div>
             </Grid>
          </Grid>
         
          
          <Grid item style={{height:"10px"}}></Grid>
          <Grid item>
             <Typography variant="body3"  className='h6'>faça a sua primeira aula com, {single?.nome}.</Typography>
          </Grid>
          <Grid item style={{height:"30px"}}></Grid>
          <Grid item>
            <Typography variant="body3"  className='h6'>Que formato de aulas deseja?</Typography>
          </Grid>
          <Grid item style={{height:"15px"}}></Grid>
          <div  style={{ background:"#fff"}} className='bg-light rounded-5 d-flex justify-content-between'>
            {lesonTypes.map((item,index)=>(<Button fullWidth variant="body1" onClick={()=>setselectedLesonTypes(index)} className={selectedLesonTypes == index?'bg-white h6 rounded-5 p-3 shadow':'h6 rounded-5 p-3'}>{item}</Button>))}
          </div>
         {
          selectedLesonTypes == 1 && 
          <Grid container flexDirection={"column"} className='p-4 rounded-5 mainColorSeundary mainTextColor mt-4' >
          <div className='d-flex gap-1'><LocationOnRoundedIcon style={{fontSize:"1.2em"}}/><Typography variant="body3">{configs?.requestInfo[0].value.replaceAll("Francisco",single?.nome)}</Typography></div>
        </Grid>
         }
          <Grid item style={{height:"20px"}}></Grid>
          <Grid item>
            <Typography variant="body3"  className='h6'>{configs?.requestInfo[3].value}</Typography>
          </Grid>
          <Grid item style={{height:"20px"}}></Grid>
          <FormControl fullWidth>
            <Select
              value={subjetsTpm}
              className='rounded-5 text-center mainTextColor'
              onChange={handleChange}>
               {subjets?.map((item,index)=>(<MenuItem value={index}>{item}</MenuItem>))}
            </Select>
          </FormControl>

          <Grid item style={{height:"20px"}}></Grid>
         
          {/* <Grid container>
              <Grid item>
                <Typography variant="body3"  className='h6'>{configs?.requestInfo[4].value}</Typography>
              </Grid>
              <div  style={{ background:"#fff", marginTop:"30px", width:"100%"}} className='bg-light rounded-5 d-flex justify-content-between'>
                {paymentMethod.map((item,index)=>(<Button fullWidth style={{fontSize: isMobile? "10px":"11px"}} variant="body1" onClick={()=>setselectedpaymentMethod(index)} className={selectedpaymentMethod == index?'bg-white h6 rounded-5 p-3 shadow':'h6 rounded-5 p-3'}> <div style={{whiteSpace:"nowrap"}}>{item}</div> </Button>))}
              </div>
          </Grid> */}
          <Grid item style={{height:"20px"}}></Grid>
          <Grid container flexDirection={"column"} className='p-4 rounded-5 mainColorSeundary mainTextColor' >
           <div className='d-flex gap-1'><VerifiedIcon style={{fontSize:"1.2em"}}/><Typography variant="body3">{configs?.requestInfo[1].value.replaceAll("Francisco",single?.nome)}</Typography></div>
           <div className='d-flex gap-1'><CalendarMonthRoundedIcon style={{fontSize:"1.2em"}}/> <Typography variant="body3">{configs?.requestInfo[2].value.replaceAll("Francisco",single?.nome)}</Typography></div>
          </Grid>
          
          <Grid item style={{height:"20px"}}></Grid>
          <Button variant="contained" style={{width:isMobile?"100%":"20vw"}}  onClick={handleSubmit} className='rounded-5 p-3 btnColor'>
          {loading? <HashLoader color="#ffffff" size={30} loading/>:configs?.requestInfo[5].value}
          </Button>
        </Grid>
        

      </Grid>
      </Dialog>
    </React.Fragment>
  );
}