import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography, Box } from '@mui/material';
import SosRoundedIcon from '@mui/icons-material/SosRounded';
import { useAlert } from '../modules/AlertContext';
import Network from '../network/networkRequests';

const PopupAuxilioEnsino = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', body: '' });
  const { showAlert } = useAlert();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e)  => {
    e.preventDefault();
    try{
      // Aqui você pode adicionar a lógica para enviar os dados do formulário
      const profileTpm = await Network.sendRequest('post', '/user/tips/sos', formData);
      if(profileTpm)
        showAlert("O seu pedido foi enviado com sucesso", 'success');
      else
        showAlert("O seu pedido foi enviado com sucesso", 'error');
    }catch(e){
      showAlert(String(e), 'error');
    }
    handleClose();
    setFormData({ name: '', email: '', body: '' })
  };

  return (
    <div>
      <div  style={{textDecoration:"none", width:"200px"}} onClick={handleClickOpen}>
                <div className="card card-body card-border-hover p-0 bg-danger">
                  <span className="p-3">
                    <h2>
                      <SosRoundedIcon className="text-white" style={{ fontSize: '1em' }} />
                    </h2>
                    <h6 className="mb-0 text-white">Precisa de ajuda?</h6>
                  </span>
                </div>
              </div>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#4b0374', color: '#fff', textAlign: 'center' ,mb:'10px' }}>
          Solicite Auxílio para Ensino Especial
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="body1" sx={{ color: '#000' }}>
              Preencha os dados abaixo para obter mais informações sobre como podemos ajudar no ensino especial para o seu filho.
            </Typography>

            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
              type="email"
            />

            <TextField
              label="Mensagem"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              name="body"
              value={formData.body}
              onChange={handleChange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PopupAuxilioEnsino;