import React from 'react';
import logoWhite from '../images/Eureca_Logo_Branco.png';
import { useLocation } from 'react-router-dom';
import { Box, Container, Grid, Typography, Divider, Link as MuiLink, List, ListItem, ListItemText, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

function Footer() {
  const location = useLocation();
  const currentYear = new Date().getFullYear();

  if (location.pathname.includes("dashboard")) {
    return null;
  }

  return (
    <Box component="footer" sx={{ pt: 4, bgcolor: '#0D0D0D', color: '#F5F5F5', position: 'relative' }}>
      <Container>
        <Grid container spacing={0} justifyContent="space-between">
            <Grid container spacing={0}>
              {/* Eureca Links */}
              <Grid item xs={3} sm={3}>
                <Typography variant="h6" gutterBottom style={{color:"#fff"}}>Sobre</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/legal" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Menções legais</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/politic/privacy" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Política de Confidencialidade</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/find-tutor" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Encontrar tutor</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/workwithus" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Trabalhe connosco</MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>

              {/* Teaching Links */}
              <Grid item xs={3} sm={3}>
                <Typography variant="h6" gutterBottom style={{color:"#fff"}}>Disciplinas</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/arts" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Artes</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/dev-pessoal" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Desenvolvimento pessoal</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/informatica" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Informática</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/language" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Línguas</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/best" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Saúde e Bem-estar</MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>

              <Grid item xs={4} sm={4}>
                <Typography variant="h6" gutterBottom style={{color:"#fff"}}>Recursos</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/magazine" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Revista Eureca</MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item>
                <Typography variant="h6" gutterBottom  style={{color:"#fff"}}>Suporte</Typography>
                <List dense>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/help" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Centro de ajuda</MuiLink>
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemText>
                      <MuiLink href="/contact" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Contacto</MuiLink>
                    </ListItemText>
                  </ListItem>
                </List>
              <Box sx={{ mt: 3, display: 'flex', gap: 1 }}>
                  <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                    <FacebookIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                    <TwitterIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                    <InstagramIcon />
                  </IconButton>
                  <IconButton href="#" sx={{ color: '#F5F5F5' }}>
                    <LinkedInIcon />
                  </IconButton>
              </Box>
              </Grid>
            </Grid>
        </Grid>

        <Divider sx={{ my: 6, bgcolor: 'grey.800' }} />

        {/* Bottom footer */}
        <Grid container justifyContent="space-between" alignItems="center" textAlign={{ xs: 'center', md: 'left' }} sx={{ pb: 6 }}>
          <Grid item xs={12} md={6}>
            <Typography variant="body2" color="textSecondary" className='text-white'>Copyright ©{currentYear} Eureca. Todos os direitos reservados.</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <List sx={{display:'flex', justifyContent:{xs:'center',md:'flex-end'},p:0}}>
              <ListItem sx={{p:0}}>
                <ListItemText>
                  <MuiLink href="/terms" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Termos de uso</MuiLink>
                </ListItemText>
              </ListItem>
              <ListItem sx={{p:0}}>
                <ListItemText>
                  <MuiLink href="/privacy" color="inherit" sx={{ '&:hover': { color: 'primary.main' } }}>Política e privacidade</MuiLink>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;