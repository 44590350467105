import React,{useState,useEffect} from 'react';

import profile from '../images/PAG-SITE-01.png';
//PAG-SITE-01.png
//S0001.png
import OnlinePredictionRoundedIcon from '@mui/icons-material/OnlinePredictionRounded';
import Person2RoundedIcon from '@mui/icons-material/Person2Rounded';
import { Grid,Container } from '@mui/material';
import {Badge,useMediaQuery,useTheme,Button,CardActions} from '@mui/material';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import SubjectsSlides from '../modules/SubjectsSlides';

function BgSec(props) {
  // const wordArray = ["\"React\"", "JavaScript", "HTML", "CSS", "Node.js"];

  const [subjects, setSubjects] = useState(JSON.parse(window.localStorage.getItem("subjs")));
  const [isFocused, setIsFocused] = useState(false);
  useEffect((e)=>{
      setSubjects(JSON.parse(window.localStorage.getItem("subjs")??"[]").slice(0,20))
  },[]);

  
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [showFixedButton, setShowFixedButton] = useState(false);
  const CONFIG_HEADER = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")))?.header;

  const [inputValue, setInputValue] = useState("Esperimenta \"disciplina\" ");
  const [currentWordIndex, setCurrentWordIndex] = useState(0);

 // Cycle through words in the array
  useEffect(() => {
    const interval = setInterval(() => {
      if (isFocused) return;
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % subjects.length);
    }, 2000); // Change word every 2 seconds

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [subjects]);

  const handleFocus = ()=>{
    setIsFocused(true);
    setInputValue("");
  };
  const handleBlur = () =>{
    setIsFocused(false)
    setInputValue("Esperimenta \"disciplina\" ");
  };

  // Replace the word "disciplina" dynamically if it exists
  const getDynamicInputValue = () => {
    if (isFocused || !subjects) {
      return inputValue; // Do not replace when input is focused
    }
    const targetWord = "\"disciplina\""; 
    const words = inputValue.split(" ");

    // Check if "disciplina" exists in the input subject.title
    const targetIndex = words.indexOf(targetWord);
    if (targetIndex !== -1) {
      words[targetIndex] = "\""+subjects[currentWordIndex]?.title+"\"";
    }

    return words.join(" ");
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

    // Effect to add window scroll event listener
    useEffect(() => {
      const handleScroll = () => {
        // Get the current scroll position.
        const currentScrollY = window.scrollY;

        // You can adjust the `scrollPositionToShowButton` to the position you want.
        const scrollPositionToShowButton = 330; // Example value.
  
        if (currentScrollY > scrollPositionToShowButton && currentScrollY < 2193) {
          setShowFixedButton(true);
        } else {
          setShowFixedButton(false);
        }
      };

     
  
      // Add the event listener when the component mounts.
      window.addEventListener('scroll', handleScroll);
  
      // Remove the event listener when the component unmounts.
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    

    return (
      <section className="bd-masthead" style={{height:isMobile?"30em":"auto"}}>

      {/* Content START */}
      <Container style={{position:"relative", top:isMobile&&"-2em"}}>
        {/* Title */}
        <div className="row align-items-center justify-content-between">
          {/* Left content START */}
          <div className="col-lg-5 col-xl-6 position-relative z-index-1 text-left text-lg-start mb-sm-0">
              <div style={{fontSize:isMobile?"2.4em":"3.5em", lineHeight:!isMobile?"60px":"40px", color:"#000", fontWeight:"bold"}}>
                {Array.isArray(CONFIG_HEADER) && CONFIG_HEADER[0].value}
              </div>
              <p className="my-4 lead" style={{color:"#000", fontSize:"20px"}}>
                {Array.isArray(CONFIG_HEADER) && CONFIG_HEADER[2].value} <strong> {Array.isArray(CONFIG_HEADER) && CONFIG_HEADER[3].value}</strong>
              </p>
            <div>
                <div className="text-center w-100">
                  <form className=" shadow rounded-5 p-2" action='/tutors/profile/all/list'>
                    <div className="input-group">
                      <input  value={getDynamicInputValue()} onChange={(e) => setInputValue(e.target.value)}
                        onFocus={handleFocus} // Set focus state to true
                        onBlur={handleBlur} // Set focus state to false
                      className="form-control p-3 border-0 rounded-5" name='p' type="search" placeholder="O que deseja aprender?"/>
                      <Button type="submit" className="btn mainBtn rounded-5 p-3 "><SearchRoundedIcon/> {!isMobile && <strong>Pesquisar</strong>}  </Button>
                    </div>
                  </form>
                </div>
                <SubjectsSlides/>
            </div>
            {isMobile &&
            <Grid container justifyContent={"space-between"} direction={"row"} className='shadow rounded-5 p-2' style={{width:"100px", marginTop:"10px",justifyContent:"center", gap:"10px"}}>
              <Grid item style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                  <Person2RoundedIcon style={{fontSize:"18px"}}/>
                  <div style={{fontSize:"15px"}}>{props.userCount}</div>
              </Grid>
              
              <Grid item style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                   <OnlinePredictionRoundedIcon style={{fontSize:"20px", color:"green"}} className='animation-blink'/>
                   <div style={{fontSize:"15px"}}>{props.userOnline}</div>
              </Grid>
            </Grid>}

          
          </div>
          {/* Right content START */}
          
          {!isMobile && <div className="col-lg-6 col-xl-6  position-relative d-flex justify-content-center d-flex">
            {/* <figure className="position-absolute bottom-0 start-50 translate-middle-x mt-4 mb-0">
              <svg
                width="450px"
                height="438px"
                viewBox="0 0 554 544"
                xmlSpace="preserve"
                className='path-change'
              >
                <path
                  className="fill-primary"
                  d="M423.3,77.2c49.5,32.5,100.4,67.2,118.4,114.5s3.5,107.1-15.4,165.7c-18.7,58.6-41.8,116.1-84,148.6 c-42.5,32.8-104.2,40.2-163.8,37.3c-59.5-3.2-116.8-17.1-164.7-47.9c-48.3-30.6-87.2-78.2-102-131.6C-3,310.5,6.6,251,25.3,194.7 C43.6,138,70.7,84.3,114.1,49.5C157.2,14.8,216.7-1,270.8,6.4C324.8,14.2,373.4,44.7,423.3,77.2z"
                />
              </svg>
            </figure>  */}
            <div className="position-relative" style={{textAlign:"right"}}>
              <img src={profile} alt="" className='w-75' style={{transform:"scale(1.4,1.4)"}} />
            </div>
          </div>}          
        </div>
      </Container>

    {isMobile && showFixedButton &&    <CardActions sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} className='bg-none p-3 d-flex flex-column' style={{zIndex:"100"}}>
                <div className="text-center w-100">
                  <form className="bg-white shadow rounded-5 p-2" action='/tutors/profile/all/list'>
                    <div className="input-group">
                      <input className="form-control p-3 border-0 rounded-5" name='p'
                        onFocus={handleFocus} 
                        onBlur={handleBlur}
                      value={getDynamicInputValue()} type="search" placeholder="O que deseja aprender?"/>
                      <Button type="submit" className="btn mainBtn rounded-5 p-3 "> <SearchRoundedIcon/> {!isMobile && <strong>Pesquisar</strong>}  </Button>
                    </div>
                  </form>
                </div></CardActions>}

  </section>
    );
}

export default BgSec;