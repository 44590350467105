import React from 'react';
import svg from '../images/element/02.svg';
import { Outlet, Link } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import GoogleLogin from 'react-google-login';
import { gapi } from 'gapi-script';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle, Alert, useMediaQuery, useTheme, Button, IconButton, TextField, Box, Divider, Typography } from '@mui/material';
import Network from '../network/networkRequests';

const configs = JSON.parse(window.localStorage.getItem("configs" + window.localStorage.getItem("version")));

function Login(props) {
  const { onClosePopup, setopenSingup } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const email = React.useRef();
  const password = React.useRef();
  const [error, setError] = React.useState({});
  const [payload, setPayload] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);

  const onSignIn = async (googleUser) => {
    setLoading(true);
    const profile = googleUser.getBasicProfile();

    const userData = {
      uid: profile.getId(),
      name: profile.getName(),
      image: profile.getImageUrl(),
      email: profile.getEmail(),
    };

    const response = await Network.sendRequest("post", "/thirt/login", userData);

    if (response.data?.error || response.statusText !== "OK") {
      setError(response.data);
    } else {
      setError({});
      setPayload(response.data);
      window.location.href = '/';
    }
    setLoading(false);
    setLoggedIn(true);
  };

  const onFailure = (error) => {
    console.log(error);
  };

  React.useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: '160773034558-53p6ceqloeenfipd47nil6jog6f5ah6r.apps.googleusercontent.com',
        scope: 'email',
      });
    }

    gapi.load('client:auth2', start);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = JSON.stringify({
      username: email.current.value,
      password: password.current.value,
    });

    const response = await Network.sendRequest("post", "/login", data);

    if (response.statusText === "OK") {
      if (response.data?.error) {
        setError(response.data);
      } else {
        setError({});
        setPayload(response.data);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);
        window.location.href = '/';
      }
      setLoading(false);
    } else {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <Box sx={{ minWidth: isMobile ? '90vw' : '30vw', p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: 1, borderColor: 'divider', pb: 2 }}>
        <Box />
        <IconButton onClick={() => onClosePopup(false)} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
      <Box sx={{ py: 5 }}>
        <Typography variant="h5" sx={{ pb: 3 }}>
          {configs?.login[0].value}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box sx={{ mb: 2 }}>
            <TextField
              inputRef={email}
              fullWidth
              type="email"
              label="Email"
              variant="outlined"
              error={!!error.email}
              helperText={error.email}
            />
          </Box>
          <Box sx={{ mb: 2 }}>
            <TextField
              inputRef={password}
              fullWidth
              type="password"
              label="Palavra-passe"
              variant="outlined"
              error={!!error.password}
              helperText={error.password}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Link to="/auth/forgot-password" className="text-secondary">
              <Typography variant="body2" color="primary">
                {configs?.login[2].value}
              </Typography>
            </Link>
          </Box>
          <Box sx={{ mt: 0 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              className='mainColor'
              sx={{
                height: 50,
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark,
                },
              }}
            >
              {loading ? <HashLoader color="#ffffff" size={30} loading /> : configs?.login[3].value}
            </Button>
            {payload?.token && <Alert severity="success" sx={{ mt: 2 }}>Autenticação bem sucedida...</Alert>}
          </Box>
        </form>
        <Divider sx={{ my: 1 }} />
        <Typography align="center" variant="body2" color="textSecondary">
          Ou
        </Typography>
        <Box sx={{ mt: 2 }}>
          <GoogleLogin
            clientId="160773034558-53p6ceqloeenfipd47nil6jog6f5ah6r.apps.googleusercontent.com"
            buttonText="Continuar com o Google"
            onSuccess={onSignIn}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            className='w-100 bg-google'
          />
        </Box>
        <Box sx={{ mt: 4, textAlign: 'center' }}>
          <Typography variant="body2">
            Não tem uma conta?{' '}
            <Button onClick={() => {
              onClosePopup(false)
              setopenSingup(true)}} variant="text" color="primary" size="small" className='mainTexColor'>
              Inscreva-se aqui
            </Button>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Login;