import React, { useState,useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import {Link,useParams} from "react-router-dom";

const SubjectsSlides = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const [subjects, setSubjects] = useState(JSON.parse(window.localStorage.getItem("subjs")));

    useEffect((e)=>{
        setSubjects(JSON.parse(window.localStorage.getItem("subjs")))
    },[]);

    const svgIcons = ["13","10","15","1117","224","1107","default","13","110","default","default","default","default","default","33","33","33","33","33","33",];

    // Configure slider settings
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 4,
        slidesToScroll: 4,
        afterChange: (index) => setCurrentSlide(index),
    };

    return (
        <div className="subjects-slider">
            <Slider {...settings} className='d-flex gap-5'>
                {subjects?.slice(0,20).map((subject, index)=>(
                    <Link to={"/tutors/profile/all/list?p="+subject.title+""} key={index} className="subject-card">
                        <div><img src={"https://c.superprof.com/img/ico/mat/"+svgIcons[index]+".svg"} alt="" srcset="" style={{width:"20px", height:"20px"}} /></div>
                        <label style={{whiteSpace:"nowrap", fontSize:"12px"}}>{subject.title}</label>
                    </Link>
                ))}
            </Slider>
        </div>
    );
};

export default SubjectsSlides;
