import React from 'react';
import CardShimmer from './cardShimmer';
import CardTutors from "../modules/CardTutors";
import {Badge,Button,useMediaQuery,useTheme,Rating} from '@mui/material';

function Instructors(props) {
    const {instructors} = props;
    const CONFIG_SEC1 = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")))?.section1;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    React.useEffect(()=>{
        window.scrollTo({top: 0});
    },[]);

    return (
        <section className={'bg-white'} style={{position:"relative",top:isMobile?"-1em":""}}>
        <div>
            {!isMobile && 
            <div className="row mb-4">
                <div className="col-12 text-left">
                <div className='d-flex justify-content-between'>
                <h3 className="fs-0 fw-bold"><span className="position-relative z-index-9">{Array.isArray(CONFIG_SEC1) && CONFIG_SEC1[0].value}</span></h3>
                    <div> <Rating value={5} readOnly /></div>
                </div>
                    <p className="mb-0">{Array.isArray(CONFIG_SEC1) && CONFIG_SEC1[1].value}</p>
                </div>
            </div>}
         
            <div className="row g-4 justify-content-center" >
            {instructors == undefined?[...Array(5).keys()].map((item) => (
                <div key={item} className='col-sm-6 col-lg-3 col-xl-3'><CardShimmer/></div>
            )):instructors?.slice(0,4)?.map((value,index) =>(<CardTutors key={index} usercard={value} simplelist={instructors} isList={true}/>))}
               
            </div>
            {/* <div className='text-center mt-2'>
                    <Button className='btn bg-white border'>Ver Todos</Button>
            </div> */}
        </div>
    </section>
    );
}

export default Instructors;