import React from 'react';
import {Link,useParams} from "react-router-dom";
import {Chip,Grid,Typography,useMediaQuery,useTheme} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import VerifiedIcon from '@mui/icons-material/Verified';
import StarRateRoundedIcon from '@mui/icons-material/StarRateRounded';
import {filterBySharedSkills} from '../util/utilities';
var CurrencyFormat = require('react-currency-format');


export default function CardTutors(props){
  const {usercard, isList,simplelist} =  props;

  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // <div className="position-relative rounded-4 profileCard h-200px" style={{background:"url("+(usercard?.profileImage?usercard?.profileImage:"https://ui-avatars.com/api/?name="+usercard?.nome)+")", backgroundSize:'cover', backgroundRepeat:'none'}}></div>
  return (
       <Grid container  sm={3} lg={3} flexDirection={"column"} className='rounded-2 mb-5'>
       <Link to={filterBySharedSkills(simplelist, usercard?.id).length > 1? `/tutors/profile/${usercard?.id}${isList ? "/list" : ""}`: "/tutors/profile/"+usercard?.id} style={{ textDecoration: "none", color: "#000" }}>
       {/* <Grid item className='profileCard rounded-2 border' style={{background:"url("+(usercard?.profileImage?usercard?.profileImage:"https://ui-avatars.com/api/?name="+usercard?.nome)+")"}}> */}
        <Grid item className='profileCard rounded-4' style={{background:"url("+usercard.profileImage+")", backgroundPosition:"center",}}>
         <div style={{padding:"15px", height:"100%"}} className='d-flex flex-column align-items-stretch justify-content-between'>
            <VerifiedIcon style={{fontSize:"30px",color:"#fff", textShadow:"4px 4px #000"}}/>
            <Grid container flexDirection={"column"} justifyContent={"space-between"} className='p-2 rounded-3 namebg'>
              <Grid item><strong>{usercard?.nome} {usercard.ultimoNome}</strong></Grid>
              <Grid item style={{fontSize:"12px"}}><small style={{fontWeight:"bold"}}>Online</small> - <small style={{fontWeight:"bold"}}>Presencial</small></Grid>
          </Grid>
         </div>
          {/* https://c.superprof.com/i/m/26558517/600/20230528061945/26558517.webp */}
        </Grid>
       
       <Grid container flexDirection={"column"} style={{marginTop:"10px"}}>
         <Grid container flexDirection={"row"} justifyContent={"space-between"} style={{marginBottom:"10px"}}>
            <Grid item><StarRateRoundedIcon style={{color:"orange", marginRight:"5px", fontSize:"20px"}}/><small><strong>{Math.round(usercard?.averageRating??0)}</strong> ({usercard?.ratingCount} avaliações)</small></Grid>
            
        </Grid>

      
        <Grid container flexDirection={"row"} justifyContent={"space-between"} style={{marginTop:"5px"}}>
            <Grid item className="text-multiline w-100">{usercard?.shortDescription??"--"}</Grid>
        </Grid>
        <Grid container flexDirection={"row"} justifyContent={"space-between"} style={{marginTop:"5px"}}>
            <Grid item><CurrencyFormat value={usercard.price??0} displayType={'text'} style={{fontSize:"15px", fontWeight:"bold",color:"rgb(77,25,113)"}} format="#.###.00 AOA"/></Grid>
        </Grid>
              
       </Grid>
       </Link>
          </Grid>);
}