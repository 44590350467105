import React, { useState } from 'react';
import pec1 from '../images/Picture 1.png';
import pec2 from '../images/Picture 2.png';
import pec3 from '../images/Picture 3.png';
import pec4 from '../images/Picture 4.png';
import pec5 from '../images/Picture 5.png';
import { Button, Card, CardContent, Typography, Box } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';

const tips = [
  {
    title: 'NUNCA INFERIORIZE…',
    content: (
      <>
        <Typography variant="body1" component="p">
          “Você já não aprende! Você é muito burro! Já falei que não é assim, mas que miúdo boelo!”  
          A missão de dar apoio emocional e cativar o interesse pelo estudo é sua (encarregado de educação).
        </Typography>
        <Typography variant="h6" component="h6" style={{ marginTop: '15px' }}>
          Nessa missão:
        </Typography>
        <ul className="list-group list-group-borderless">
          {[
            'Antes de tudo, é importante que identifique o estilo de aprendizagem da criança, para melhor encaminhamento;',
            'Evite gritar ou ser rude!',
            'Esteja disponível para ajudar nas lições…e nos casos em que não esteja, delegue, mas, ainda assim, faça um esforço de participar;',
            'Obs.: AJUDAR NÃO É FAZER NO LUGAR DESTE',
            'Cria, com ajuda da criança, uma rotina diária de estudo...fazendo-o perceber os motivos;',
            'Definam metas alcançáveis, de acordo aos temas a serem aprendidos;',
            'Incentive ao uso de ambiente apropriado, distante de distrações;',
            'Dê suporte emocional e saiba reconhecer a evolução da criança;',
          ].map((text, index) => (
            <li key={index} className="list-group-item h6 fw-normal d-flex">
              <i className="bi bi-patch-check-fill text-success me-2" />
              {text}
            </li>
          ))}
        </ul>
      </>
    ),
    backgroundColor: '#FFF3E0',
  },
  {
    title: 'Use o que tem, seja criativo…!',
    content: (
      <>
        <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', backgroundColor: '#FFECB3', padding: '20px', borderRadius: '8px' }}>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <div style={{ flex: '1' }}>
              <Typography variant="h5" component="h2" style={{ color: '#000', fontWeight: 'bold', marginBottom: '10px' }}>
                A Criatividade no Estudo: Aprendendo com Goku
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginBottom: '15px' }}>
                Um exemplo prático que podemos tirar dos desenhos animados que as crianças adoram está no desenho <b>“Dragon Ball”</b>, de artes marciais, e no seu personagem principal, <b>“GoKu”</b>. O GoKu, pela sua natureza, tem a <b>necessidade</b> de testar os seus limites e se tornar cada vez mais forte. Por isso, ele desafia os guerreiros mais fortes em combate de artes marciais, buscando sempre superá-los.
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginBottom: '15px' }}>
                Este comportamento de buscar desafios e testar limites é algo que também pode ser aplicado no estudo. Quando nos desafiamos, não apenas absorvemos o conteúdo, mas também nos forçamos a crescer e melhorar continuamente. A ideia é usar o que temos ao nosso redor, como o exemplo de Goku, para nos impulsionar a alcançar novos patamares.
              </Typography>
            </div>
            <img src={pec1} alt="GoKu" style={{ maxWidth: '40%', marginLeft: '20px', marginTop: '15px', borderRadius: '8px' }} />
          </div>
        </div>
      </>
    ),
    backgroundColor: '#FFECB3',
  },  
  {
    title: '“Aqui aproveitamos e traduzimos à criança, de forma criativa”:',
    content: (
      <>
        <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', backgroundColor: '#FFE0B2', padding: '20px', borderRadius: '8px' }}>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <div style={{ flex: '1' }}>
              <Typography variant="h5" component="h2" style={{ color: '#000', fontWeight: 'bold', marginBottom: '10px' }}>
                A Analogia Criativa: GoKu e a Criança
              </Typography>
              <Typography variant="h6" component="h6" style={{ color: '#000', marginTop: '15px', fontStyle: 'italic' }}>
                GoKu = Criança
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                No contexto de aprendizado, podemos associar o personagem GoKu a uma criança em processo de desenvolvimento. Assim como GoKu enfrenta desafios em sua jornada para se tornar mais forte, a criança também enfrenta obstáculos ao buscar o sucesso acadêmico.
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                <b>Desafio GoKu -{'>'}</b> derrotar um guerreiro = <b>Desafio Criança -{'>'} </b> ser aprovado numa prova.
              </Typography>
            </div>
            <img src={pec2} alt="Desafio GoKu" style={{ maxWidth: '40%', marginLeft: '20px', marginTop: '15px', borderRadius: '8px' }} />
          </div>
        </div>
      </>
    ),
    backgroundColor: '#FFE0B2',
  },  
  {
    title: 'Sessões de Treinos Focadas',
    content: (
      <>
        <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', backgroundColor: '#FFD54F', padding: '20px', borderRadius: '8px' }}>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <div style={{ flex: '1' }}>
              <Typography variant="h5" component="h2" style={{ color: '#000', fontWeight: 'bold', marginBottom: '10px' }}>
                O Poder das Sessões de Treinos Focadas
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                Antes de enfrentar qualquer desafio, GoKu dedica-se a <u>sessões de treinos muito focadas</u>, com o objetivo de vencer, ou ao menos oferecer resistência ao seu oponente, mostrando assim o seu real valor. Essas sessões de treino são uma preparação estratégica para superar obstáculos maiores.
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                Assim como no aprendizado, é fundamental que nos preparemos adequadamente antes de enfrentar grandes desafios. A prática e o foco são essenciais para desenvolver habilidades, e a preparação mental e física nos ajuda a enfrentar qualquer situação com mais confiança.
              </Typography>
            </div>
            <img src={pec3} alt="Sessões de Treinos" style={{ maxWidth: '40%', marginLeft: '20px', marginTop: '15px', borderRadius: '8px' }} />
          </div>
        </div>
      </>
    ),
    backgroundColor: '#FFD54F',
  },
  {
    title: 'Persistência e Dedicação',
    content: (
      <>
        <div className="text-black" style={{ fontFamily: 'Georgia, serif', lineHeight: '1.6', backgroundColor: '#FFCA28', padding: '20px', borderRadius: '8px' }}>
          <div className="d-flex gap-3 flex-column flex-md-row">
            <div style={{ flex: '1' }}>
              <Typography variant="h5" component="h2" style={{ color: '#000', fontWeight: 'bold', marginBottom: '10px' }}>
                O Poder da Persistência e Dedicação
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                Com o treino completo, GoKu está sempre pronto para o combate e se esforça ao máximo para vencer! No entanto, é importante frisar que GoKu sabe perder, mas nunca desiste de tentar até conseguir a vitória. Essa perseverança e dedicação são essenciais para o sucesso em qualquer jornada.
              </Typography>
              <Typography variant="h5" component="h5" style={{ color: '#000', marginTop: '20px', fontWeight: 'bold' }}>
                Como Motivamos a Criança para Persistir?
              </Typography>
              <Typography variant="body1" component="p" style={{ color: '#000', marginTop: '15px' }}>
                Um exemplo prático de como podemos motivar a criança e impactar sua dedicação é conhecer seus gostos e usá-los como impulso para o desenvolvimento. Ao associar um objetivo àquilo que a criança gosta, criamos uma conexão emocional que a mantém focada e empenhada.
              </Typography>
              <Typography variant="h6" component="h6" style={{ color: '#000', marginTop: '20px', fontStyle: 'italic' }}>
                A IDEIA É CONHECER OS GOSTOS DA CRIANÇA E USAR ISSO COMO IMPULSO…
              </Typography>
            </div>
            <img src={pec4} alt="Persistência de GoKu" style={{ maxWidth: '40%', marginLeft: '20px', marginTop: '15px', borderRadius: '8px' }} />
          </div>
          <img src={pec5} alt="Motivação da Criança" style={{ width: '100%', marginTop: '20px', borderRadius: '8px' }} />
        </div>
      </>
    ),
    backgroundColor: '#FFCA28',
  }  
];

function ChildrenTips() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box className="container" display="flex" flexDirection="column" alignItems="center" width="100%">
      
      <Card
        style={{
          width: '100%',
          backgroundColor: tips[activeStep].backgroundColor,
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        <CardContent>
          <Typography variant="h5" component="h2" gutterBottom>
            {tips[activeStep].title}
          </Typography>
          {tips[activeStep].content}
        </CardContent>
      </Card>
      <Box display="flex" justifyContent="space-between" width="100%" mb={2} mt={2}>
        <Button
          variant="contained"
          color="secondary"
          disableElevation
          className='rounded-2'
          onClick={handleBack}
          style={{visibility:activeStep !== 0?"visible":"hidden"}}
          disabled={activeStep === 0}
          startIcon={<ArrowBack />}
        >
          Back
        </Button>
        <Button
          variant="contained"
          disableElevation
          color="secondary"
          className='rounded-2'
          onClick={handleNext}
          style={{visibility:activeStep !== tips.length - 1?"visible":"hidden"}}
          disabled={activeStep === tips.length - 1}
          endIcon={<ArrowForward />}>
           Próxima Dica
        </Button>
      </Box>
    </Box>
  );
}

export default ChildrenTips;
