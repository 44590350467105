import React,{useState} from 'react';
import {headers} from '../util/utilities';
import HashLoader from "react-spinners/HashLoader";
import { useLocation } from "react-router-dom"
import {Slider, Chip,Button,useMediaQuery,Radio,FormControlLabel,Switch,Container} from '@mui/material';
import CardShimmer from './cardShimmer';
import CardTutors from "../modules/CardTutors";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/material/styles';
import Network from '../network/networkRequests';
import { useAlert } from '../modules/AlertContext';
import {getDecodedToken,filterBySharedSkills} from '../util/utilities';
import { useParams } from 'react-router-dom';

var CurrencyFormat = require('react-currency-format');

const configs = JSON.parse(window.localStorage.getItem("configs"+ window.localStorage.getItem("version")));

function InstructorList(props) {
    const {instructors,skills,porcent} = props;
    const { id } = useParams();
    const base_user_info = getDecodedToken();
    const { showAlert } = useAlert();
    const [instrList,setinstrList] = useState(instructors);
    const [selectedValue, setSelectedValue] = useState('');
    const [open, setOpen] = React.useState(false);

    const [inviteSend, setinviteSend] = React.useState(false);

    const p = React.useRef();
    const [input,setInput] = React.useState(null);
    const [loading,setloading] = React.useState(true);
    const [filters,setfilters] = React.useState(['Tipo de aula','Distância','Preços','Nível','Tempo de resposta']);
    const [educationLevels, setEducationLevels] = useState([
        "Todos os níveis",
        "1º ciclo",
        "2º ciclo",
        "3º ciclo",
        "10º ano",
        "11º ano",
        "12º ano",
        "Ensino superior",
        "Formação para adultos",
        "Mestrado",
        "Doutoramento",
        "MBA",
        "Pós-Graduação"
      ]);

      const [localeselection, setlocaleselection] = useState([
        "Procurar perto de mim",
        "Presencial",
        "Online"
      ]);

    const [Activefilter,setActivefilter] = React.useState(null);

    const [searchText,SetSearchText] = React.useState('');

    const toggle = (porcent > 0.4);
    
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    
    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const update=(val)=>{
        SetSearchText(p.current.value);
    }

    const applyText=()=>{
        setInput(searchText);
    }
    const handleFilter=(index)=>{
        setActivefilter(index);
        setOpen(true);
    }

    const handleClose = () => {
        changeElementByIndex();
        setOpen(false);
    };

    const marks = [
        {
          value: 1,
          label: '1km',
        },
        {
          value: 50,
          label: '50km',
        }
    ];

    const marksMoney = [
        {
          value: 2000,
          label: '2.000Kz',
        },
        {
          value: 10000,
          label: '10.000Kz',
        }
    ];
    const changeElementByIndex = () => {
        // if( Activefilter !== 0 &&  Activefilter !== 3 &&  Activefilter !== 4 &&  Activefilter !== 5)
        //     return;
        // Create a copy of the array
        const updatedLevels = [...filters];
        // Update the element at the specified index
        updatedLevels[Activefilter] = selectedValue;
        // Update the state
        setfilters(updatedLevels);
    };

    const handleChangeChoose = (event) => {
        setSelectedValue(event.target.value);
      };
    
    const controlProps = (item) => ({
        checked: selectedValue === item,
        onChange: handleChangeChoose,
        value: item,
        name: 'color-radio-button-demo',
        inputProps: { 'aria-label': item },
      });

      const reorderById = (list, idToFilter) => {
        // Encontra o item com o idToFilter
        const filtered = list.find(item => item.id == idToFilter);
        // Filtra os itens que não possuem o idToFilter
        const remaining = list.filter(item => item.id != idToFilter);
        // Retorna a nova lista ordenada
        return filtered ? [filtered, ...remaining] : list;
    };

    const getAllUsers = async ()=>{
        const response = await Network.getUsersData({});
        if(response.data){
          if(id == "all"){
              setinstrList(response.data);
              setloading(false);
              return;
          }

          const sortedData = reorderById(response.data, id);
          const rev = filterBySharedSkills(sortedData,id);
          console.log(rev)
          setinstrList(rev);
        }

        setloading(false);
      };

      
    React.useEffect(()=>{
        window.scrollTo({top: 0});
        const sortedData = reorderById(instructors, id);

        if(instructors.length > 0){
            setinstrList(sortedData);
        }

        getAllUsers();

        if(params.get("p") != null)
            setInput(params.get("p"));

    },[])
 
    async function sugestsHundle(showAlert, setinviteSend)  {
        showAlert('Estamos a processar o pedido', 'success');
        setinviteSend(true);
        await Network.sendRequest('post', '/sugestSearch', {"userId": base_user_info.id,"sugests":input});
    }    

    const renderSearchResults = (searchInput) => {
       
        const filteredResultsSubjects = instrList?.filter((item) => item?.skills?.some((skill) =>skill.title.toLowerCase().includes(searchInput.toLowerCase())));

        if (filteredResultsSubjects.length > 0)
            return filteredResultsSubjects.map((item, index) => (<CardTutors key={index} usercard={item} />));
        
        const filteredResults = instrList.filter((item) =>`${item?.nome} ${item?.ultimoNome}`.toLowerCase().includes(searchInput.toLowerCase()));
      
        if (filteredResults.length > 0)
            return filteredResults.map((item, index) => (
                <CardTutors key={index} usercard={item} />
              ));
        
       if(!loading)
          return (
            <div className="container mt-4 d-flex justify-content-center flex-column align-items-center border rounded-2 p-3">
              <p>
                Nenhum resultado encontrado para <strong>{searchInput}</strong>
              </p>
              <div className="d-flex justify-content-center flex-column align-items-center mt-4">
                <p>Deseja uma pesquisa avançada para "{searchInput}"?</p>
                <Button
                  disabled={inviteSend}
                  className={`rounded-2 ${inviteSend ? 'p-3' : 'mainBtn p-3'}`}
                  onClick={async () => {
                    await sugestsHundle(showAlert, setinviteSend);
                  }}
                >
                  Sim, por favor
                </Button>
              </div>
            </div>
          );
       
      };

    return (
        <Container className='bg-white mt-2 p-2'>
            <section className="py-0 mt-3">
            <div>
                    <h3 className="text-dark">{window.location.pathname === "/get-tutors"? "Os melhores tutores de Angola!":configs.teachers[0]?.value}</h3>
                    <form className="border rounded-2 p-1 mt-4"  style={{maxWidth:isMobile?"":"40vw"}}>
                        <div className="input-group input-borderless">
                            <input
                            className="form-control me-1"
                            type="search"
                            placeholder={!input? "Procurar um Explicador":input}
                            ref={p}
                            onChange={update}
                            />
                            <button type="button" className="btn mainBtn mb-0 rounded-2" onClick={applyText}>
                            {!isMobile && <span>Pesquisar</span>} <i className="fas fa-search" /> 
                            </button>
                        </div>
                    </form>
                </div>
            </section>

            <section className="pt-4">
            <div>
                    {/* Search option START */}
                    <div className="d-flex gap-3 mb-4 align-items-center" style={{overflowX:"auto"}}>
                        {filters.map((ite,key)=>(<Chip key={key} label={ite} onClick={()=>handleFilter(key)} style={{cursor:"pointer", background:"#fff", border:"1px solid #aaa"}} className={key === Activefilter? "mainBtn":""}/>))}
                    </div>

                    <div className="row g-4 justify-content-center" style={{padding:isMobile? "12px":""}}>
                        {/* {loading?[...Array(10).keys()]?.map((item) => (
                            <div key={item} className='col-sm-6 col-lg-4 col-xl-3'><CardShimmer/></div>
                        )):""} */}
                    {
                    !input ? (
                        instrList?.map((item, index) => (
                        <CardTutors key={index} usercard={item} />
                        ))
                    ) : (
                        renderSearchResults(input)
                    )
                    }
                </div>

                {/* Instructor list END */}
                {/* Pagination START */}
                <nav
                className="mt-4 d-flex justify-content-center"
                aria-label="navigation"
                >
                    {loading?
                 <HashLoader color="#831caf" size={30} loading/>: <Button disabled={inviteSend} className='btnColor rounded-3 border  p-3 mt-4'>{configs?.teachers[1].value}</Button>}
             
                </nav>
                {/* Pagination END */}
            </div>
            </section>

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                onClose={handleClose}>
                <DialogContent className='p-3'>
                    <div>
                        <div style={{overflowY:"auto", minWidth:fullScreen?"80vw":"30vw"}}>
                            {/* {
                                Activefilter !== 0 &&  Activefilter !== 3 &&  Activefilter !== 4 && Activefilter !== 1 && (
                                    <div className='text-danger text-center mt-4'>Sem filtros para esta categoria, tente mais tarde.</div>
                                )
                            } */}

                        {Activefilter === 2 && <div className='p-2 d-flex flex-column gap-5'>
                           <div className='d-flex flex-column'>
                           <label>Preço médio: 2.500Kz</label>
                           <label>Defina o quanto quer gastar:</label>
                           </div>
                           <div className='d-flex flex-column' style={{marginRight:"25px", marginLeft:"25px"}}>
                            <Slider
                                defaultValue={3000}
                                marks={marksMoney}
                                getAriaValueText={(e)=>setSelectedValue(`Budget máximo:${e}Kz`)}
                                min={2000}
                                max={10000}
                                step={500}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value)=> <CurrencyFormat value={value} displayType={'text'} format="#.###.00 Kz" />} 
                                sx={{
                                    color:"#4b1172",
                                    '& .MuiSlider-valueLabel': {
                                      backgroundColor: '#4b1172', // Background color
                                      color: 'white', // Text color
                                    },
                                  }}
                            />
                           </div>
                        </div>}

                        {Activefilter === 1 && <div className='d-flex flex-column p-4 gap-5'>
                            <label>Desloco-me num raio de: 50km +</label>
                            <Slider
                                defaultValue={15}
                                marks={marks}
                                getAriaValueText={(e)=>setSelectedValue(`< ${e} km`)}
                                min={1}
                                max={50}
                                valueLabelDisplay="on"
                                valueLabelFormat={(value)=>`${value} km`} 
                                sx={{
                                    color:"#4b1172",
                                    '& .MuiSlider-valueLabel': {
                                      backgroundColor: '#4b1172', // Background color
                                      color: 'white', // Text color
                                    },
                                  }}
                            />
                        </div>}

                        {Activefilter === 0 && <div className='d-flex flex-column p-2'>
                            {localeselection.map((item,key)=>(<FormControlLabel
                                value={key === 0?'':item}
                                control={<Radio {...controlProps(key === 0?'':item)} color="secondary"/>}
                                label={item}
                                labelPlacement="End"
                            />))}
                        </div>}
                        {Activefilter === 3 && <div className='d-flex flex-column p-2'>
                            {educationLevels.map((item,key)=>(<FormControlLabel
                                value={key === 0?'':item}
                                control={<Radio {...controlProps(key === 0?'':item)} color="secondary"/>}
                                label={item}
                                labelPlacement="End"
                            />))}
                        </div>}
                        {Activefilter === 4 && <div className='d-flex justify-content-between align-items-center'>
                            <strong>
                            Ver apenas os professores que respondem no dia
                            </strong>
                            <Switch label="" onChange={(e)=>{setSelectedValue(e.target.checked?"No dia":"Tempo de Resposta")}} color="secondary" />
                        </div>}
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className='border d-flex justify-content-between'>
                    <Button autoFocus onClick={handleClose} className='rounded text-dark p-3' style={{fontSize:"12px", width:"100px"}}>Eliminar</Button>
                    <Button onClick={handleClose} autoFocus className='mainBtn rounded p-3' style={{fontSize:"12px", width:"100px"}}>Aplicar</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default InstructorList;
