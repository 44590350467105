import React,{useRef, useState,useEffect} from 'react';
import { Outlet, Link } from "react-router-dom";
import svg from '../images/element/02.svg';
import HashLoader from "react-spinners/HashLoader";
import Alert from '@mui/material/Alert';
import {Select,Button,TextField,MenuItem,FormControl,InputLabel,InputAdornment,IconButton,OutlinedInput} from '@mui/material';
import Network from '../network/networkRequests';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useAlert } from '../modules/AlertContext';

function SignUp(props) {
  const {setopenSignUp,fullScreen} = props;
  const { showAlert } = useAlert();
  const email = useRef();
  const nome = useRef();
  const ultimoNome = useRef();
  const genero = useRef();
  const municipio = useRef();
  const provincia = useRef();
  const [stateIndex,setstateIndex] = useState(0);
  const telefone = useRef();
  const password = useRef();
  const Repassword = useRef();
  const [error,SetError] = useState({});
  const [loading,Setloading] = useState(false);
  const [payload,SetPayload] = useState({});
  const [response,SetResponse] = useState({});
  const [showPassword, setShowPassword] = React.useState(false);

  const states = [
  'Bengo',
  ,'Benguela',
  ,'Bié',
  ,'Cabinda',
  ,'Cuando-Cubango',
  ,'Cunene',
  ,'Huambo',
  ,'Huíla',
  ,'Kwanza Sul',
  ,'Kwanza Norte',
  ,'Luanda',
  ,'Lunda Norte',
  ,'Lunda Sul',
  ,'Malanje',
  ,'Moxico',
  ,'Namibe',
  ,'Uíge',
  ,'Zaire'];
  
  const handleChange = (event) => {
    setstateIndex(event.target.value);
  };



const handleClickShowPassword = () => setShowPassword((show) => !show);

const handleMouseDownPassword = (event) => {
  event.preventDefault();
};

  const register = async e =>{
        e.preventDefault();
        Setloading(true);
        
        const formData = new FormData(e.target);
        const dataPayload = Object.fromEntries(formData.entries());
        delete dataPayload.password2;
        dataPayload.role = "USER";

        console.log(dataPayload);
        console.log(e.target);

        SetPayload(dataPayload);

        const response = await Network.sendRequest('post', '/singup', dataPayload);

        // .then(function (response) {
          SetResponse(response.data);
          if(response.data?.error){
            SetError(response.data?.error);
            showAlert(response.data?.error, 'error');
          }else{
            if(response.data?.success){
              showAlert("O seu primeiro passo para o conhecimento foi dado!", 'success');
              setTimeout(function(){ window.location = "/"; }, 2000);
            }
        }
        //   }
        Setloading(false);
        // })
        // .catch(function (error) {
        //   SetError({error:error});
        //   console.log(error);
        //   Setloading(false);
        // });
       
  }

  return (
          <section className="p-4" style={{minWidth: !fullScreen?"550px":""}}>
                <div className="col m-auto">
                      <h3>Inscreva-se!</h3>
                      <p className="lead mb-3 small">A um passo do sucesso.</p>
                      {/* Form START */}
                      <form onSubmit={register}>
                        <div className="mb-3 d-flex gap-3">
                          <TextField
                              ref={nome}
                              name='nome'
                              type="text"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Primeiro"
                              id="exampleInputEmail1"
                            />
                            </div>
                          <div className="mb-3 d-flex gap-3">
                          <TextField
                              ref={ultimoNome}
                              name="ultimoNome"
                              type="text"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Último nome"
                              id="exampleInputEmail1"
                            />
                        </div>
                        <div className="mb-3">
                          <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={email}
                              name="email"
                              type="email"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="E-mail"
                              id="exampleInputEmail1"
                            />
                          </div>
                        </div>
                        <div className="mb-3 d-flex gap-3">
                          <FormControl className="TextField-group TextField-group-lg w-50">
                           <InputLabel id="demo-simple-select-label" style={{marginTop:"10px"}}>Genero</InputLabel>
                            <Select className="form-control border-0 bg-light rounded-end ps-1" name="genero" ref={genero} value={genero.current?.value}>
                                <MenuItem value="Masculino">Masculino</MenuItem>
                                <MenuItem value="Femenino">Femenino</MenuItem>
                            </Select>
                          </FormControl>
                          <FormControl className="TextField-group TextField-group-lg w-50">
                           <InputLabel id="demo-simple-select-label" style={{marginTop:"10px"}}>Provincia/estado</InputLabel>
                            <Select className="form-control border-0 bg-light rounded-end ps-1" name='address' ref={provincia} value={provincia.current?.value}>
                              {states.map((e,index)=>(<MenuItem value={e}>{e}</MenuItem>))}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="mb-3">
                          <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={telefone}
                              name='phone'
                              type="tel"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Telefone"
                              id="exampleInputEmail1"
                            />
                          </div>
                        </div>
                       
                        <div className="mb-3">
                          <FormControl className="TextField-group  TextField-group-lg w-100" variant="outlined">
                          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                            <OutlinedInput
                              ref={password}
                              name='senha'
                              type={showPassword ? 'text' : 'password'}
                              className=" border-0 bg-light rounded-end ps-1"
                              label="Palavra passe*"
                              endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                  </IconButton>
                                </InputAdornment>
                              }
                            />
                          </FormControl>
                        </div>
                        
                        <div className="mb-3">
                        <div className="TextField-group TextField-group-lg">
                            <TextField
                              ref={Repassword}
                              name='password2'
                              type="password"
                              className="form-control border-0 bg-light rounded-end ps-1"
                              label="Confirmar Palavra passe*"
                              id="inputPassword6"
                            />
                          </div>
                        </div>
                       
                        {/* Check box */}
                        <div className="mb-3">
                         {error.error?<Alert severity="error">{error.error}</Alert>:""}
                        
                          <div className="form-check small">
                            Ao inscrever-se, concorda com os
                              <a href="#">  termos de serviço</a>
                          </div>
                        </div>
                        {/* Button */}
                        <div className="align-items-center mt-0">
                          <div className="d-grid">
                          {<Button className="btn mainColor mb-0 d-flex justify-content-center" style={{height:"50px"}} type="submit">{loading? <HashLoader color="#ffffff" size={30} loading/>:"Criar Conta"}</Button>}
                           
                          </div>
                        </div>
                      </form>
                      {/* Form END */}
                      <div className="mt-4 text-center d-flex align-items-center justify-content-center">
                        <span>Já tem uma conta?</span>
                        <Link className="p-3 rounded-2" to="/login">
                            <div type="button" className='p-2 mainTextColor small'>Entrar</div> 
                        </Link>
                      </div>
                </div>
          </section>
    );
}

export default SignUp;