import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import EmailIcon from '@mui/icons-material/Email';
import LinkIcon from '@mui/icons-material/Link';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function ShareDialog(props) {
  const { openShare, setOpenShare, shareLink =  window.location.href, handleDownloa} = props;

  const handleCopy = () => {
    navigator.clipboard.writeText(shareLink);
    alert('Link copied to clipboard!');
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={() => setOpenShare(false)}
        aria-labelledby="customized-dialog-title"
        open={openShare}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Partilhar
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOpenShare(false)}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <Typography variant="subtitle1" gutterBottom>
            Partilhar numa publicação
          </Typography> */}
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item>
              <Tooltip title="Copy Link">
                <IconButton onClick={handleCopy} className='border'>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="WhatsApp">
                <IconButton color="success" className='border'>
                  <WhatsAppIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Facebook">
                <IconButton color="primary" className='border'>
                  <FacebookIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Twitter">
                <IconButton color="primary" className='border'>
                  <TwitterIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Email">
                <IconButton color="secondary" className='border'>
                  <EmailIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Baixar perfil">
                <IconButton color="secondary" className='border' onClick={handleDownloa}>
                  <CloudDownloadIcon />
                </IconButton>
              </Tooltip>
            </Grid>
           
          </Grid>
          <Typography variant="subtitle2" sx={{ mt: 3 }}>
            Link para partilhar:
          </Typography>
          <Grid container spacing={1} alignItems="center" sx={{ mt: 1 }} >
            <Grid item xs>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={shareLink}
                InputProps={{readOnly: true}}
              />
            </Grid>
            <Grid item>
              <Button variant="outlined" className='rounded' startIcon={<ContentCopyIcon />} onClick={handleCopy}>
                Copiar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
