import React, { useState } from 'react';
import { Box, Typography, Paper, Avatar, IconButton,useMediaQuery,useTheme } from '@mui/material';
import { ArrowBackIos, ArrowForwardIos, Star } from '@mui/icons-material';

const SliderShow = (props) => {
  const {testimonials,current} = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box textAlign="center" p={4} maxWidth={isMobile?"54em":"64em"} mx="auto" >
      {/* Carousel container */}
      <Box
        display="flex"
        overflow="hidden"
        position="relative"
        style={{width:"100%", left:isMobile?"-40px":"10px"}}
        sx={{
          width: '100%',
          scrollSnapType: 'x mandatory',
          height: 470
        }}
      >
        {/* Inner container for sliding */}
        <Box
          display="flex"
          transition="transform 0.5s ease"
          sx={{
            transition: 'transform 0.5s ease-in-out',
            overflowX:isMobile?"scroll":"hidden",
            overflowY:"hidden",
          }}
        
        >
          {testimonials.map((testimonial, index) => (
            <Paper
              key={testimonial.id}
              elevation={0}
              sx={{
                flex: '0 0 33.33%',
                p: 3,
                m: 1,
                transform: `translateX(-${current * (100 / 1)}%)`, // Adjust for 3 items
                borderRadius: 3,
                backgroundColor: Number(index) % 2 === 0?"#E0F7FA":"#D1C4E9",
                textAlign: 'left',
                transition: 'all 0.5s ease',
      
              }}
            >
              <Box display="flex" flexDirection="column">
               <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
                <Avatar
                    src={testimonial.userProfileImage} // Replace with actual path or URL
                    alt={testimonial.proffname}
                    sx={{ width: 60, height: 60, mb: 2}}
                  />
                <div>
                <Typography >
                    {testimonial.proffname}
                  </Typography>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>
                    {testimonial.role??"Prof francês"}
                  </Typography>
                </div>
               </Box>
                <div className='d-flex flex-column justify-content-between' style={{height:"22em"}}>
                  <div className='mb-4' style={{fontSize:"18px", fontWeight:"bold"}}>
                    {testimonial.comment}
                  </div>
                  <Box display="flex" justifyContent="space-between" width="100%">
                    <Typography variant="body2" color="textSecondary" className='bg-white p-2 rounded d-flex gap-2'>
                      {testimonial.userName.split(" ")[0]}
                      <Box display="flex" alignItems="center">
                      {[...Array(testimonial.val)].map((_, i) => (
                        <Star key={i} fontSize="small" sx={{ color: '#FFD700' }} />
                      ))}
                    </Box>
                    </Typography>
                  
                  </Box>

                </div>
              
              </Box>
            </Paper>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default SliderShow;
