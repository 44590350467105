import React, { useEffect, useState, useRef } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stepper,
  Step,
  StepLabel,
  useMediaQuery,
  useTheme,
  Typography,
  Chip,
  Button,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Box,
  Slide,
  Paper,
  Container
} from '@mui/material';
import { CheckCircleRounded as CheckCircleRoundedIcon, Close as CloseIcon } from '@mui/icons-material';
import HashLoader from "react-spinners/HashLoader";
import { useAlert } from '../modules/AlertContext';

import Network from '../network/networkRequests';
import Camera from './camera';
import PaymentPopup from "../views/PaymentPopup";
import selfIcoon from "../images/vlog.png";
import { getDecodedToken } from '../util/utilities';

const configs = JSON.parse(window.localStorage.getItem("configs" + window.localStorage.getItem("version")));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ApplyInstructor({ skills }) {
  const [minDate, setMinDate] = useState('');

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  


  const [stepIndex, setStepIndex] = useState(0);
  const [imageSrc, setImageSrc] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [habilidades, setHabilidades] = useState(skills);
  const [skillsInstructor, setSkillsInstructor] = useState([]);
  const [skillInput, setSkillInput] = useState('');
  const [errors, setErrors] = useState({});

  const [userData, setUserData] = useState({
    "user_id": getDecodedToken().email,
    "occupation": "",
    "birth_date": "",
    "city": "",
    "province": "",
    "street": "",
    "phone": "",
    "job_title": "",
    "job_start_date": "",
    "job_end_date": "",
    "institution": "",
    "expert": "",
    "school_start_date": "",
    "school_end_date": "",
    "skills": skillsInstructor,
    "description": "",
    "image_source": imageSrc,
    "payment": 0,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { showAlert } = useAlert();
  const descricaoRef = useRef();
  const base_user_info = getDecodedToken();

  useEffect(() => {
    const today = new Date();
    const thirteenYearsAgo = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate());
    const formattedDate = thirteenYearsAgo.toISOString().split('T')[0];
    setMinDate(formattedDate);
  }, []);

  const handleStepChange = (direction) => {
    if (validateStep()) {
      setStepIndex(prevStepIndex => prevStepIndex + direction);
    }
  };

  const handleSkillChange = (e) => {
    setSkillInput(e.target.value);
  };

  const addSkill = (skill) => {
    setSkillsInstructor([...skillsInstructor, skill]);
    setHabilidades(habilidades.filter(h => h.title !== skill.title));
    setSkillInput('');
  };

  const applyChangeValue = (e) => {
    const { name, value } = e.target;
    setUserData(prevUserData => ({
      ...prevUserData,
      [name]: value
    }));
  };

  const validateStep = () => {
    let valid = true;
    const newErrors = {};

    if (stepIndex === 0) {
      if (!userData.birth_date) {
        newErrors.birth_date = "Data de nascimento é obrigatória.";
        valid = false;
      } else {
        const birthDate = new Date(userData.birth_date);
        const thirteenYearsAgo = new Date();
        thirteenYearsAgo.setFullYear(thirteenYearsAgo.getFullYear() - 16);

        if (birthDate > thirteenYearsAgo) {
          newErrors.birth_date = "Você deve ter mais de 16 anos.";
          valid = false;
        }
      }
      if (!userData.phone) {
        newErrors.phone = "Número de telefone é obrigatório.";
        valid = false;
      }
      if (!userData.province) {
        newErrors.province = "Provincia é obrigatória.";
        valid = false;
      }
      if (!userData.street) {
        newErrors.street = "Minicipio é obrigatório.";
        valid = false;
      }
    } else if (stepIndex === 1) {
      if (!userData.institution) {
        newErrors.institution = "Instituição é obrigatória.";
        valid = false;
      }
      if (!userData.expert) {
        newErrors.expert = "Especialidade é obrigatória.";
        valid = false;
      }
      if (!descricaoRef.current.value) {
        newErrors.description = "Apresentação é obrigatória.";
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const sendRequest = async (e) => {
    e.preventDefault();

    if (!validateStep()) {
      return;
    }
    showAlert("O seu pedido está a ser processado", 'info');
    setLoading(true);
    try {
      userData.image_source = localStorage.getItem('image');
      userData.skills = skillsInstructor;
      const response = await Network.sendRequest('post', '/instructor/setinstructor', userData);
      if (response.data?.error) {
        showAlert(response.data.error, 'error');
      } else if (response?.data === 'Already exists a request' || !response?.data?.token) {
        showAlert("O seu pedido já foi enviado", 'info');
      } else {
        window.location.href = '/dashboard/profile';
      }
    } catch (error) {
      showAlert(error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    'Informação pessoal',
    'Apresentação',
    'Verificação',
  ];

  return (
    <Container >
      <Paper elevation={1} style={{ padding: '15px', marginTop: '24px', marginBottom: '20px' }}>
        <Typography variant="h4" align="center" gutterBottom>Solicitar admissão</Typography>
        <Typography variant="body1" paragraph align="center">
          {configs?.submition[0]?.value}
        </Typography>
        <Stepper activeStep={stepIndex} alternativeLabel style={{ paddingBottom: '24px', }}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{isMobile ? index : label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <form onSubmit={sendRequest}>
          {stepIndex === 0 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  type="date"
                  label="Data de nascimento"
                  value={userData.birth_date}
                  name="birth_date"
                  onChange={applyChangeValue}
                  inputProps={{ min: minDate }}
                  variant="outlined"
                  error={!!errors.birth_date}
                  helperText={errors.birth_date}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Número de telefone"
                  value={userData.phone}
                  name="phone"
                  onChange={applyChangeValue}
                  variant="outlined"
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth variant="outlined" error={!!errors.province}>
                  <InputLabel>Provincia</InputLabel>
                  <Select
                    value={userData.province}
                    name="province"
                    onChange={applyChangeValue}
                    label="Provincia"
                  >
                    <MenuItem value=""><em>Selecione a Provincia</em></MenuItem>
                    <MenuItem value="Bengo">Bengo</MenuItem>
                    <MenuItem value="Benguela">Benguela</MenuItem>
                    <MenuItem value="Bié">Bié</MenuItem>
                    <MenuItem value="Cabinda">Cabinda</MenuItem>
                    <MenuItem value="Cuando-Cubango">Cuando-Cubango</MenuItem>
                    <MenuItem value="Cunene">Cunene</MenuItem>
                    <MenuItem value="Huambo">Huambo</MenuItem>
                    <MenuItem value="Huíla">Huíla</MenuItem>
                    <MenuItem value="Kwanza Sul">Kwanza Sul</MenuItem>
                    <MenuItem value="Kwanza Norte">Kwanza Norte</MenuItem>
                    <MenuItem value="Luanda">Luanda</MenuItem>
                    <MenuItem value="Lunda Norte">Lunda Norte</MenuItem>
                    <MenuItem value="Lunda Sul">Lunda Sul</MenuItem>
                    <MenuItem value="Malanje">Malanje</MenuItem>
                    <MenuItem value="Moxico">Moxico</MenuItem>
                    <MenuItem value="Namibe">Namibe</MenuItem>
                    <MenuItem value="Uíge">Uíge</MenuItem>
                    <MenuItem value="Zaire">Zaire</MenuItem>
                  </Select>
                  {errors.province && <Typography color="error">{errors.province}</Typography>}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Minicipio"
                  value={userData.street}
                  name="street"
                  onChange={applyChangeValue}
                  placeholder="Kilamba Kiaxi"
                  variant="outlined"
                  error={!!errors.street}
                  helperText={errors.street}
                />
              </Grid>
            </Grid>
          )}
          {stepIndex === 1 && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Apresentação"
                  name='description'
                  placeholder="Mais de 300 palavras..."
                  inputRef={descricaoRef}
                  onChange={applyChangeValue}
                  variant="outlined"
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Instituicão"
                  value={userData.institution}
                  name="institution"
                  onChange={applyChangeValue}
                  variant="outlined"
                  error={!!errors.institution}
                  helperText={errors.institution}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Especialidade"
                  value={userData.expert}
                  name="expert"
                  onChange={applyChangeValue}
                  variant="outlined"
                  error={!!errors.expert}
                  helperText={errors.expert}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" className="border p-2 mainColor rounded-2">Habilidades/Disciplinas de domínio <span className="text-danger">*</span></Typography>
                <Box display="flex" alignItems="center" mt={2}>
                  <TextField
                    fullWidth
                    placeholder="Qual a habilidade ou disciplina domina?"
                    value={skillInput}
                    onChange={handleSkillChange}
                    variant="outlined"
                  />
                  <Button variant="contained" color="secondary" onClick={() => setSkillInput('')} style={{ marginLeft: '8px', height: '50px', width: '50px' }}>
                    <CloseIcon />
                  </Button>
                </Box>
                <Box mt={2}>
                  {habilidades?.filter(skill => skill.title.toLowerCase().includes(skillInput.toLowerCase()) && skillInput !== "").map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill.title}
                      onClick={() => addSkill(skill)}
                      variant="outlined"
                      style={{ cursor: 'pointer', margin: "5px", fontSize:"13px"}}
                    />
                  ))}
                  <Box mt={2}>
                    {skillsInstructor.map((skill, index) => (
                      <Chip key={index} label={skill.title} color="success" style={{ marginBottom: '8px' }} />
                    ))}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
          {stepIndex === 2 && (
            <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" className="StepperStHeight">
              <Typography variant="h6">Envie uma imagem (selfie) com o seu BI <span className="text-danger">*</span></Typography>
              <Box
                onClick={() => setShowPopup(true)}
                border={2}
                borderColor="primary.main"
                p={4}
                mt={2}
                borderRadius={4}
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                style={{ cursor: 'pointer' }}
              >
                <Typography variant="body2">Esteja em um lugar iluminado</Typography>
                <Typography variant="body2">Garanta que o seu BI esteja visível</Typography>
                <img src={selfIcoon} alt="Self Icon" style={{ width: '100px', opacity: 0.3 }} />
              </Box>
            </Box>
          )}
          <Box mt={4} display="flex" justifyContent="space-between">
            <Button
              disabled={stepIndex < 1}
              variant="contained"
              type='button'
              disableElevation
              style={{ backgroundColor: "#e57373", color: "#fff" }}
              onClick={() => handleStepChange(-1)}
            >
              Anterior
            </Button>

            {/* <Button
              variant="contained"
              type={stepIndex === steps.length - 1 ? "submit" : "button"}
              onClick={() => handleStepChange(1)}
              className="mainColor"
            >
              {stepIndex === steps.length - 1 ? "Submeter o pedido" : "Próximo"}
            </Button> */}

        {stepIndex === steps.length - 1 && 
          <Button variant="contained" type="submit" className="mainColor">
            Submeter o pedido
          </Button>}

        {stepIndex < steps.length - 1 &&
          <Button
            type="button"
            variant="contained"
            onClick={() => handleStepChange(1)}
            className="mainColor"
          >
            Próximo
          </Button>
        }


          </Box>
        </form>
      </Paper>

      <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={() => setOpen(false)}>
        <DialogContent>
          {loading ? (
            <Box display="flex" justifyContent="center" alignItems="center">
              <HashLoader color={theme.palette.primary.main} size={30} loading />
            </Box>
          ) : (
            <CheckCircleRoundedIcon color="success" style={{ fontSize: '50px' }} />
          )}
        </DialogContent>
      </Dialog>

      <Dialog open={showPopup} onClose={() => setShowPopup(false)}>
        <DialogTitle style={{ fontSize: '13px', textAlign: 'center' }}>
          Mantenha-se no centro da imagem e segure o seu bilhete de identidade.
        </DialogTitle>
        <DialogActions>
          <Camera setImageSrc={setImageSrc} handleClosePopup={() => setShowPopup(false)} />
        </DialogActions>
      </Dialog>
    
    </Container>
  );
}

export default ApplyInstructor;